import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';
import { AdminConsumerSharedModule } from '~shared/admin-consumer-shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutBackgroundImgComponent } from './components/about-background-img/about-background-img.component';
import { AdminOrderDetailComponent } from './components/admin-order-detail/admin-order-detail.component';
import { CtaFormComponent } from './components/cta-form/cta-form.component';
import { DisputeItemComponent } from './components/dispute-item/dispute-item.component';
import { ExceptionComponent } from './components/exception/exception.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { TrustIconFormComponent } from './components/trust-icon-form/trust-icon-form.component';
import { CreateEditWeddingColorComponent } from './dialogs/create-edit-wedding-color/create-edit-wedding-color.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { CallToActionPageComponent } from './pages/call-to-action-page/call-to-action-page.component';
import { CategoriesPageComponent } from './pages/categories/categories-page/categories-page.component';
import {
  CreateEditCategoryPageComponent
} from './pages/categories/create-edit-category-page/create-edit-category-page.component';
import { DisputesPageComponent } from './pages/disputes-page/disputes-page.component';
import { ExceptionsPageComponent } from './pages/exceptions-page/exceptions-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ImpersonateComponent } from './pages/impersonate/impersonate.component';
import { ItemsPageComponent } from './pages/items-page/items-page.component';
import { ListingDetailPageComponent } from './pages/items-page/listing-detail-page/listing-detail-page.component';
import { MainMenuCategoriesPageComponent } from './pages/main-menu-categories-page/main-menu-categories-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { UserTableComponent } from './pages/people-page/components/user-table/user-table.component';
import { PeoplePageComponent } from './pages/people-page/people-page.component';
import { PrivacyPolicyEditorPageComponent } from './pages/privacy-policy-editor-page/privacy-policy-editor-page.component';
import { SellOnWedzeePageComponent } from './pages/sell-on-wedzee-page/sell-on-wedzee-page.component';
import { SellerAgreementPageComponent } from './pages/seller-agreement-page/seller-agreement-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { TermsOfServicePageEditorComponent } from './pages/terms-of-service-page-editor/terms-of-service-page.component';
import { TrustIconsPageComponent } from './pages/trust-icons-page/trust-icons-page.component';
import { WeddingColorsPageComponent } from './pages/wedding-colors-page/wedding-colors-page.component';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { CategoriesService } from './services/categories/categories.service';
import { HomePageService } from './services/home-page/home-page.service';
import { WeddingColorsService } from './services/wedding-colors/wedding-colors.service';
import { SetFeaturedListingsComponent } from './components/set-featured-listings/set-featured-listings.component';
import { SiteEventsComponent } from './pages/site-events/site-events.component';
import { TestShipmentTrackingComponent } from './pages/test-shipment-tracking/test-shipment-tracking.component';
import { ResetDatabasePageComponent } from './pages/reset-database-page/reset-database-page.component';

@NgModule({
  entryComponents: [
    CreateEditWeddingColorComponent
  ],
  declarations: [
    AppComponent,
    HomePageComponent,
    CategoriesPageComponent,
    CreateEditCategoryPageComponent,
    AboutPageComponent,
    ItemsPageComponent,
    PeoplePageComponent,
    UserTableComponent,
    TermsOfServicePageEditorComponent,
    PrivacyPolicyEditorPageComponent,
    CtaFormComponent,
    TrustIconFormComponent,
    MainMenuCategoriesPageComponent,
    CallToActionPageComponent,
    TrustIconsPageComponent,
    WeddingColorsPageComponent,
    CreateEditWeddingColorComponent,
    SettingsPageComponent,
    AboutBackgroundImgComponent,
    SellOnWedzeePageComponent,
    SellerAgreementPageComponent,
    TextEditorComponent,
    DisputesPageComponent,
    DisputeItemComponent,
    FaqPageComponent,
    ExceptionsPageComponent,
    OrdersPageComponent,
    AdminOrderDetailComponent,
    ExceptionComponent,
    PrettyJsonPipe,
    ListingDetailPageComponent,
    ImpersonateComponent,
    SetFeaturedListingsComponent,
    SiteEventsComponent,
    TestShipmentTrackingComponent,
    ResetDatabasePageComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AdminConsumerSharedModule,
    ColorPickerModule,
    QuillModule
  ],
  providers: [
    CategoriesService,
    HomePageService,
    WeddingColorsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
