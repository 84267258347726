<div class="full-width text-left">
  <h3 class="mat-display-1">Call to action</h3>
</div>

<mat-card style="text-align: left; margin: 20px;">
  <mat-card-header>
    <h3 class="mat-headline">Box Color</h3>
  </mat-card-header>
  <mat-card-content>
    <span 
      [style.background]="boxColor"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [cpCancelButton]="false"
      [cpOutputFormat]="'hex'"
      [(colorPicker)]="boxColor"
    ></span>
    <br>
    <button (click)="updateBoxColor()" style="display: inline-block;" [disabled]="boxColor === originalBoxColor" mat-flat-button color="accent">
      Save box color
    </button>
    <button mat-stroked-button (click)="boxColor = originalBoxColor" *ngIf="boxColor !== originalBoxColor">Cancel</button>
  </mat-card-content>
</mat-card>



<wz-cta-form name="Primary" [originalDoc]="primaryCta" (updated)="updatePrimaryCta($event, !!primaryCta)"></wz-cta-form>
<wz-cta-form name="Secondary" [originalDoc]="secondaryCta" (updated)="updateSecondaryCta($event, !!secondaryCta)"></wz-cta-form>
