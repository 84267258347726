<mat-card class="text-left margin">
  <mat-card-title>
    {{dispute.id}}
  </mat-card-title>
  <mat-card-subtitle>
    Dispute ID: {{dispute.id}}
  </mat-card-subtitle>  
  <mat-card-subtitle>
    Buyer name: {{dispute.name}}
  </mat-card-subtitle>
  <mat-card-subtitle>
    Buyer email: <a target="_blank" [href]="'mailto:' + dispute.email">{{dispute.email}}</a>
  </mat-card-subtitle>

  <mat-card-content>
    <div class="mat-subheading-1 no-margin">Buyer description:</div>
    <div>{{dispute.buyerDescription}}</div>
    <ng-container  *ngIf="dispute?.imageUrls && dispute.imageUrls?.length > 0">
      <br><br>
      <div class="mat-subheading-1 no-margin">Images:</div>
      <div class="full-width text-left children-inline-block">
        <img class="dispute-img" *ngFor="let u of dispute.imageUrls; let i = index;" [src]="u" alt="Dispute image" (click)="previewImage(u, i)">
      </div>
    </ng-container>

    <br><br>

    <div class="mat-subheading-1 no-margin">Order # {{dispute.orderId | orderId}}</div>
    <div class="mat-subheading-1 no-margin">Billing postal code {{ dispute.order.billToAddressZip }}</div>
    <wz-order-item-with-tracking *ngIf="dispute.order && dispute.listing" [order]="dispute.order" [listing]="dispute.listing"></wz-order-item-with-tracking>
  </mat-card-content>
  <mat-card-actions>
    <button class="margin" *ngIf="!dispute.isResolved" mat-stroked-button (click)="markAsResolved()">
      <mat-icon>done</mat-icon>
      Mark as resolved
    </button>
    <button class="margin" mat-stroked-button (click)="archive()">
      <mat-icon>delete</mat-icon>
      Archive
    </button>
  </mat-card-actions>
</mat-card>
