<div class="page-header">
  <span class="mat-display-2" style="display: inline-block;">Site settings</span>
</div>

<div class="full-width text-left">
  <form [formGroup]="royaltyFeeForm" *ngIf="royaltyFeeForm as form">
      <mat-form-field>
        <mat-label style="font-size: x-large">Royalty Fee %</mat-label>
        <input (keyup)="form.markAsTouched(); form.updateValueAndValidity()" formControlName="royaltyPercent" style="font-size: x-large" type="number" matInput placeholder="Enter royalty fee">
      </mat-form-field>
      <button *ngIf="form.touched && form.valid" (click)="saveUpdateRoyaltyFee()" mat-raised-button color="accent">
        <mat-icon>check</mat-icon>
      </button>
  </form>
</div>

<br><br>

<div class="full-width text-center">
  <form [formGroup]="siteDisabledForm" *ngIf="siteDisabledForm as form">
    <div class="full-width children-inline-block text-left">
      <span class="mat-headline" style="display: inline-block;">
        Disable the site
        <mat-slide-toggle
          (change)="form.get('isSiteDisabled').setValue($event.checked); form.markAsTouched()"
          color="accent"
          [checked]="form.get('isSiteDisabled').value"
        >
        </mat-slide-toggle>
      </span>
      <button [disabled]="form.invalid || !form.touched" (click)="saveUpdateSiteDisabled()" mat-raised-button color="accent" style="float: right;">
        <mat-icon>check</mat-icon>
        Save Changes
      </button>
    </div>

    <wz-text-editor
      [value]="existingDoc?.siteDisabledHtml"
      placeholder="Enter disabled description..."
      (updated)="form.get('siteDisabledHtml').setValue($event); form.markAsTouched()"
    ></wz-text-editor>
  </form>
</div>
