import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import * as firebase from 'firebase/app';
import { from, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ICategory, ICategoryWithSubcategories } from 'wz-types/categories';
import { FirestoreRefs, Globals } from '~shared/classes';
import { wzCatchObservableError } from '~shared/services';

import { Category } from '../../classes/category.class';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private fileName = 'categories.service.ts';

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  public static instantiateCategory(firestore: AngularFirestore, catDoc: ICategory): Category {
    return new Category(firestore, FirestoreRefs.categories, catDoc);
  }

  getAllDocuments(): Observable<ICategory[]> {
    const getCategories = () => from(FirestoreRefs.categories.ref.orderBy('name', 'asc').get());
    return <Observable<ICategory[]>>getCategories().pipe(
      map((querySnap: QuerySnapshot<ICategory>) => {
        return querySnap.docs.map((doc: QueryDocumentSnapshot<ICategory>) => doc.data());
      }),
      wzCatchObservableError(this.fileName, 'getAllDocuments()')
    );
  }

  getAllCategoriesWithSubcategories(): Observable<Category[]> {
    return this.getAllDocuments().pipe(
      filter((cats: ICategory[]) => !!cats),
      map((cats: ICategory[]) => cats.map(c => CategoriesService.instantiateCategory(this.firestore, c))),
      map((allCategories: Category[]) => {
        const allCatsLookup = {};
        allCategories.forEach((c: Category) => allCatsLookup[c.id] = c);
        const getSubCategories = (cat: Category) => <ICategoryWithSubcategories[]>allCategories.filter(c => c.parentCategoryId === cat.id);
        const result = allCategories.map((c: Category) => {
          (<any>c).subCategories = getSubCategories(c);
          return c;
        });
        return result;
      }),
      wzCatchObservableError(this.fileName, 'getAllCategoriesWithSubcategories()')
    );
  }

  getCategory(id: string): Observable<Category> {
    let getCatDoc = () => from(FirestoreRefs.categories.doc(id).get()).pipe(
      map((snapshot: firebase.firestore.DocumentSnapshot) => {
        const c = <ICategory>snapshot.data();
        Globals.categoriesLookup[c.id] = c;
        return CategoriesService.instantiateCategory(this.firestore, c);
      }),
      wzCatchObservableError(this.fileName, 'getCategory()')
    );
    if (Globals.categoriesLookup && Globals.categoriesLookup[id]) getCatDoc = () => of(CategoriesService.instantiateCategory(this.firestore, Globals.categoriesLookup[id]));
    return getCatDoc();
  }

  deleteCategory(categoryId: string): Observable<void> {
    const deleteCatDoc = () => from(FirestoreRefs.categories.doc(categoryId).delete());
    return from(FirestoreRefs.categories.ref.where('parentCategoryId', '==', categoryId).get()).pipe(
      mergeMap((querySnap: QuerySnapshot<ICategory>) => {
        const updatePromises = querySnap.docs.map((s: DocumentSnapshot<ICategory>) => s.ref.update({ parentCategoryId: null }));
        return from(Promise.all(updatePromises));
      }),
      mergeMap(() => deleteCatDoc()),
      wzCatchObservableError(this.fileName, 'deleteCategory()')
    );
  }

  createUpdateCategory(doc: ICategory, isUpdating: boolean, subCategoryIds?: string[]): Observable<void> {
    const setMethod = isUpdating ? 'update' : 'set';
    const saveDoc = () => FirestoreRefs.categories.doc(doc.id)[setMethod](doc);
    return from(saveDoc()).pipe(
      mergeMap(() => {
        const addSubCatBatch = this.firestore.firestore.batch();
        subCategoryIds.forEach((subId: string) => {
          const subCatRef = FirestoreRefs.categories.doc(subId);
          addSubCatBatch.update(subCatRef.ref, { parentCategoryId: doc.id });
        });
        return from(addSubCatBatch.commit());
      }),
      wzCatchObservableError(this.fileName, 'createUpdateCategory()')
    );
  }


  getMainMenuCategories(): Observable<Category[]> {
    const self = this;
    return from(FirestoreRefs.categories.ref.where('isInMainMenu', '==', true).get()).pipe(
      map((querySnap: firebase.firestore.QuerySnapshot) => {
        const cats = querySnap.docs.map((q) => q.data());
        return cats.map((c: ICategory) => CategoriesService.instantiateCategory(this.firestore, c));
      }),
      wzCatchObservableError(this.fileName, 'getMainMenuCategories()')
    );
  }

  setMainMenuCategories(newMainMenyCatIds: string[]): Observable<void> {
    return this.getAllDocuments().pipe(
      mergeMap((allCats: ICategory[]) => {
        const oldMainMenuCatIds = allCats.filter(c => c.isInMainMenu).map(c => c.id);
        const idsToSetFalse = oldMainMenuCatIds.filter(i => newMainMenyCatIds.indexOf(i) === -1);

        const setBatch = this.firestore.firestore.batch();
        idsToSetFalse.forEach(id => {
          setBatch.update(FirestoreRefs.categories.ref.doc(id), { isInMainMenu: false });
        });

        newMainMenyCatIds.forEach(id => {
          setBatch.update(FirestoreRefs.categories.ref.doc(id), { isInMainMenu: true });
        });

        return from(setBatch.commit());
      }),
      wzCatchObservableError(this.fileName, 'setMainMenuCategories()')
    );
  }


  getCategoryFromLookup(categoryId: string) {
    return Globals.categoriesLookup[categoryId];
  }

}
