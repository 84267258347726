<mat-card style="text-align: left; margin: 20px;">
  <mat-card-header>
    <h3 class="mat-headline">{{name + ' call to action'}}</h3>
  </mat-card-header>
  <mat-card-content>
    <form *ngIf="ctaForm" class="cta-form" [formGroup]="ctaForm">
      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput placeholder="Title" type="text">
        <mat-error *ngIf="!ctaForm.get('title').valid">Title is required!</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Message</mat-label>
        <input formControlName="message" matInput placeholder="Message" type="text">
      </mat-form-field>
      <div class="img-color-container">
        <div>
          <mat-label style="display: block;">Display image</mat-label>
          <wz-add-image
            imgType="callToAction"
            [aspectRatio]="name === 'Primary' ? { width: 2.25, height: 1 } : { width: 3, height: 2 }"
            [originalImgUrl]="originalDoc?.imgUrl"
            (imgAdded)="updateImgUrl($event)">
          </wz-add-image>
        </div>
        <div [ngSwitch]="!!getCtrl('routerLink') && !!getCtrl('routerLink').value">
          <mat-label>Link to</mat-label><br>
          <button *ngSwitchCase="false" mat-raised-button color="accent" (click)="selectCategoryLink()">Select Category Link</button>
          <button *ngSwitchCase="true" mat-raised-button color="accent" (click)="selectCategoryLink()">
            <mat-icon>link</mat-icon>
            {{getCtrl('routerLinkName').value}}
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button style="width: 100%;" mat-raised-button color="accent" (click)="submitCtaForm()" [disabled]="ctaForm.invalid || !ctaForm.touched">
      {{ 'Save ' + name.toLowerCase() + ' call to action' }}
    </button>
  </mat-card-actions>
</mat-card>
