import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Globals } from '~shared/classes';

import { IWedzeeOrder } from '../../../../../../../types/orders';
import { OrdersStore } from '../../../../../shared/src/lib/stores/orders.store';

@Component({
  selector: 'app-test-shipment-tracking',
  templateUrl: './test-shipment-tracking.component.html',
  styleUrls: ['./test-shipment-tracking.component.scss']
})
export class TestShipmentTrackingComponent implements OnInit {
  trackForm: FormGroup;

  orderSelectMethod: 'type' | 'select' = 'type';

  statusOptions = [
    'UNKNOWN', 'PRE_TRANSIT', 'TRANSIT', 'DELIVERED', 'RETURNED', 'FAILURE'
  ];

  listingIdOptions = [];
  orderIdOptions = [];

  shippingResponse: string;

  constructor(
    private http: HttpClient,
    private ordersStore: OrdersStore,
    private firebaseDb: AngularFirestore
  ) { }

  ngOnInit() {
    this.trackForm = new FormGroup({
      orderId: new FormControl('', Validators.required),
      listingId: new FormControl({value: '', disabled: true}, Validators.required),
      shippoStatus: new FormControl('', Validators.required),
    });

    this.trackForm.controls.orderId.valueChanges.pipe(
      filter(id => id && id.length > 15),
      mergeMap(id => {
        console.log('GOT HERE', id);
        return this.firebaseDb.collection('orders').doc(id).get().pipe(
          map((snap) => snap.data())
        );
      })
    ).subscribe((order: IWedzeeOrder) => {
      this.listingIdOptions = order.listingIds;
      this.trackForm.controls.listingId.enable();
    });

    this.setToMostRecentOrder();
  }

  public onSubmit() {
    const url = `${Globals.environment.apiUrl}dev/test-shippo`;
    console.log(url);
    this.http.post(url, this.trackForm.getRawValue()).pipe(
      take(1)
    ).subscribe((response: any) => {
      this.shippingResponse = response;
    });
  }

  private async setToMostRecentOrder() {
    const qSnap = await this.firebaseDb.collection('orders').ref.orderBy('createdTimestamp').limit(10).get();
    const doc = qSnap.docs[0].data();
    this.orderIdOptions = qSnap.docs.map(d => d.data().id);
    this.trackForm.controls.orderId.setValue(doc.id);
  }

}
