import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Globals } from '~shared/classes/global.class';
import { AlertService } from '~shared/services/alert/alert.service';

@Component({
  selector: 'wz-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Globals.emailValidator])
  });

  signInUrl: string;
  localUrl: string;
  signInEmail: string;

  constructor(
    private http: HttpClient,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
  }

  async signInAsUser() {
    const email = this.emailForm.controls.email.value.trim();
    const requestUrl = `${(Globals.environment as any).apiUrl}users/impersonate?email=${email}`;
    try {
      const resp = await this.http.get(requestUrl).pipe(take(1)).toPromise() as any;
      this.signInUrl = resp.url;
      this.signInEmail = email;
      const token = resp.url.split('=')[1];
      this.localUrl = `http://localhost:4300/impersonate?token=${token}`;
    } catch (e) {
      this.alertSrv.alert('User not found', 'This user could not be found. Are you sure the email is correct?');
    }
  }

}
