<mat-card class="text-left margin">
  <button mat-icon-button (click)="delete.emit(e.id)">
    <mat-icon>delete</mat-icon>
  </button>
  <p>{{e.timestamp | date:'short'}}</p>
  <p *ngIf="!!e.href">URL: <b>{{e.href}}</b></p>
  <ng-container [ngSwitch]="e.fileName === 'error-handler.class.ts'">
    <p>
      File: 
      <b>
        <span *ngSwitchCase="true">Uncaught</span>
        <span *ngSwitchCase="false">{{e.fileName}}</span>
      </b>
    </p>
    <p *ngSwitchCase="false">Function: <b>{{e.functionName}}</b></p>
  </ng-container>
  <p *ngIf="!!e.userId">User ID: <b>{{e.userId}}</b></p>

  <ng-container *ngIf="!!e.error">
    <span>Error:</span>
    <div style="background-color: whitesmoke; white-space: pre-wrap; padding: 20px;" [innerHTML]="e.error | prettyJson: true"></div>
  </ng-container>

  <ng-container *ngIf="!!e.stack">
    <span>Stack:</span>
    <div style="background-color: whitesmoke; white-space: pre-wrap; padding: 20px;" [innerHTML]="e.stack | prettyJson"></div>

  </ng-container>

  <ng-container *ngIf="!!e.navigator || !!e.window">
    <p>Device:</p>
    <div *ngIf="!!e.navigator">
      <p>Navigator:</p>
      <div style="background-color: whitesmoke; white-space: pre-wrap; padding: 20px;" [innerHTML]="e.navigator | prettyJson"></div>
    </div>
    <div *ngIf="!!e.window">
      <p>Window:</p>
      <div style="background-color: whitesmoke; white-space: pre-wrap; padding: 20px;" [innerHTML]="e.window | prettyJson"></div>
    </div>
  </ng-container>
  
</mat-card>