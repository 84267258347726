<div class="full-width text-left children-inline-block">
  <h3 class="mat-display-1 no-margin">
    {{ (isEditing ? 'Edit ' : 'Create ') + 'a listing' }}
  </h3>
</div>

<mat-card class="text-left">
  <mat-card-header>
    <mat-card-title>
      <h3 class="mat-subheading-2">Photos</h3>
    </mat-card-title>
    <mat-card-subtitle>
      Add as many photos as you can so buyers can see every detail. Try to get as many angles as possible. Avoid using your camera's flash and try to include an everyday object for scale.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="children-inline-block text-left">
    <wz-add-image style="display: inline-block;" [aspectRatio]="{ width: 1, height: 1 }" widthPx="125" imgType="product" (imgAdded)="addImage($event)" resetAfterEmit="true"></wz-add-image>

    <ng-container *ngIf="itemForm.get('images').value as images" class="children-inline-block;">
      <wz-add-image *ngFor="let img of images" widthPx="125" [aspectRatio]="{ width: 1, height: 1 }" imgType="product" [originalImgUrl]="img.imgUrl" (imgAdded)="updateImage($event, i)"></wz-add-image>
    </ng-container>

  </mat-card-content>
</mat-card>

<div *ngIf="!!itemForm">
  {{itemForm.getRawValue() | json}}
</div>
