<div class="page-header">
  <span class="mat-display-2">Home</span>
</div>

<mat-card style="text-align: left; margin: 20px;" *ngIf="bannerMessage$ | async as msg">
  <mat-card-title>
    Banner Message
  </mat-card-title>
  <form *ngIf="!!bannerMessageForm" [formGroup]="bannerMessageForm">
    <mat-form-field class="full-width">
      <mat-label>Banner Message</mat-label>
      <input formControlName="bannerMessage" [value]="msg" matInput placeholder="Banner message..." type="text">
    </mat-form-field>
    <button class="full-width" mat-raised-button color="accent" (click)="updateBannerMessage()" [disabled]="!bannerMessageForm.touched || bannerMessageForm.invalid">
      Save banner message
    </button>
  </form>
</mat-card>

<wz-set-featured-listings></wz-set-featured-listings>

<wz-cta-form name="Primary" [originalDoc]="primaryCta" (updated)="updatePrimaryCta($event, !!primaryCta)"></wz-cta-form>
<wz-cta-form name="Secondary" [originalDoc]="secondaryCta" (updated)="updateSecondaryCta($event, !!secondaryCta)"></wz-cta-form>

<!-- <wz-trust-icon-form></wz-trust-icon-form> -->

<br><br><br>

<!-- <wz-about-background-img></wz-about-background-img> -->

<mat-card class="full-width" class="main-menu-cats-row">
  <div class="full-width text-left children-inline-block">
      <h3 class="mat-display-1">Main Menu Categories</h3>
      <button color="accent" mat-raised-button (click)="selectMainMenuCategories()">Change</button>
  </div>
  <div>
    <wz-category *ngFor="let c of mainMenuCategories" [doc]="c" [routerLink]="['categories', 'edit', c.id]"></wz-category>
  </div>
</mat-card>

<!-- <div style="text-align: left!important;">
  <mat-card *ngFor="let c of mainMenuCategories" class="main-menu-cat-card">
    <mat-card-header>
      <mat-card-title>
        <h3 class="mat-title">{{c.name}}</h3>
      </mat-card-title>
    </mat-card-header>
    <img style="max-width:100%!important;" mat-card-image [src]="c.imageUrl">
    <mat-card-actions>
      <button mat-raised-button color="accent" [routerLink]="['categories', 'edit', c.id]">
        View/Edit
      </button>
    </mat-card-actions>
  </mat-card>
</div> -->



