
<div  [ngSwitch]="!!isEditing" class="page-header">
  <span class="mat-display-2" *ngSwitchCase="false">Create a new category</span>
  <span class="mat-display-2" *ngSwitchCase="true">Edit category</span>
  <button [disabled]="categoryForm.invalid || !categoryForm.touched" (click)="submitCategoryForm()" mat-raised-button color="accent">
    <mat-icon>done</mat-icon>
    <span *ngSwitchCase="false">Save</span>
    <span *ngSwitchCase="true">Save Changes</span>
  </button>
</div>

<form *ngIf="!!categoryForm" [formGroup]="categoryForm" style="text-align: left">
  <mat-form-field class="full-width">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput placeholder="Name" type="text" style="font-size: x-large">
    <mat-error *ngIf="!categoryForm.get('name').valid">Category name is required!</mat-error>
  </mat-form-field>

  <!-- <mat-form-field class="full-width">
    <mat-label>Description (Optional)</mat-label>
    <textarea formControlName="description" matInput placeholder="Description" type="text"></textarea>
    <mat-error *ngIf="!categoryForm.get('description').valid">Description is required!</mat-error>
  </mat-form-field> -->


  <div class="parent-categories-bar">
    <span class="mat-headline">Gender</span> <br>
    <mat-hint>(Optional)</mat-hint>
  </div>

  <ng-container *ngIf="categoryForm.get('gender') as gCtrl">
    <mat-checkbox (change)="clickedGenderCheckbox($event.checked)">Assign a gender?</mat-checkbox>
    <br><br>
    <mat-radio-group *ngIf="willHaveGender" formControlName="gender">
      <mat-radio-button style="margin-right: 25px;" value="m">Male</mat-radio-button>
      <mat-radio-button value="f">Female</mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <br><br><br>


  <div class="parent-categories-bar">
    <span class="mat-headline">Parent Category</span>
    <button mat-raised-button color="accent" (click)="selectParentCategories()">Select</button>
  </div>

  <mat-chip-list *ngIf="categoryForm.get('parentCategories') as parentControl">
    <mat-chip *ngFor="let parent of parentControl.value" [removable]="true" (removed)="removeParentCategory(parent)">
      {{parent.name}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <mat-hint *ngIf="!parentControl.value || (parentControl.value && parentControl.value.length === 0)">No parent category</mat-hint>
  </mat-chip-list>


  <br><br>


  <div class="parent-categories-bar">
    <span class="mat-headline">Subcategories</span>
    <button mat-raised-button color="accent" (click)="selectSubcategories()">Select Subcategories</button>
  </div>

  <mat-chip-list *ngIf="categoryForm.get('subCategories') as subControl">
    <mat-chip *ngFor="let sub of subControl.value" [removable]="true" (removed)="removeSubCategory(sub)">
      {{sub.name}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <mat-hint *ngIf="!subControl.value || (subControl.value && subControl.value.length === 0)">No subcategories</mat-hint>
  </mat-chip-list>

  <br><br>

  <!-- <div class="parent-categories-bar">
    <span class="mat-headline">Mutually exclusive categories</span>
    <button mat-raised-button color="accent" (click)="selectMutuallyExclusiveCategories()">Select mutually exclusive</button>
  </div> -->

  <!-- <mat-chip-list *ngIf="categoryForm.get('mutuallyExclusiveCategories') as mutualControl">
    <mat-chip *ngFor="let mut of mutualControl.value" [removable]="true" (removed)="removeMutualCategory(mut)">
      {{mut.name}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <mat-hint *ngIf="!mutualControl.value || (mutualControl.value && mutualControl.value.length === 0)">No mutually exclusive categories</mat-hint>
  </mat-chip-list> -->



  <br>
  <br>
  <h3 class="mat-headline">Image</h3>
  <mat-hint style="margin-bottom: 25px!important;">Recomended resolution ratio is 540px wide x 320px high</mat-hint>
  <br>
  <br>
  <wz-add-image *ngIf="!isLoading"
    (imgAdded)="updateImg($event)"
    [originalImgUrl]="categoryForm.get('imageUrl')?.value"
    [aspectRatio]="{ width: 3, height: 2 }"
    imgType="category"
    placeholderSrc="assets/img-default/just-married.png"
  ></wz-add-image>
</form>


<br>
<div class="full-width text-center" style="margin-top: 100px;">
  <button mat-raised-button *ngIf="isEditing" (click)="deleteCategory()" color="warn">Delete</button>
</div>
