import { Component, OnInit } from '@angular/core';
import { AngularFirestore, DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from as fromPromise, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { IWedzeeSiteSettings } from 'wz-types/pages-policies.d';
import { FirestoreRefs } from '~shared/classes';
import { AlertService } from '~shared/services';



@Component({
  selector: 'wz-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  settingsDocRef: DocumentReference;
  siteDisabledForm: FormGroup;
  royaltyFeeForm: FormGroup;
  existingDoc: IWedzeeSiteSettings;

  constructor(
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private alertSrv: AlertService
  ) {
  }

  ngOnInit() {
    this.settingsDocRef = FirestoreRefs.pagesAndPolicies.doc('siteSettings').ref;

    this.siteDisabledForm = this.formBuilder.group({
      isSiteDisabled: [undefined],
      siteDisabledHtml: [undefined]
    });

    this.royaltyFeeForm = this.formBuilder.group({
      royaltyPercent: [undefined, [Validators.required, Validators.max(99.99), Validators.min(0)]]
    });

    fromPromise(this.settingsDocRef.get()).pipe(
      map((snap: DocumentSnapshot<IWedzeeSiteSettings>) => {
        this.existingDoc = snap.data();
        const { isSiteDisabled, siteDisabledHtml, royaltyPercent } = this.existingDoc || <any>{};
        this.siteDisabledForm.get('isSiteDisabled').setValue(isSiteDisabled);
        this.siteDisabledForm.get('siteDisabledHtml').setValue(siteDisabledHtml);
        this.royaltyFeeForm.get('royaltyPercent').setValue(royaltyPercent);
      }),
      take(1)
    ).subscribe();
  }


  saveUpdateSiteDisabled(): void {
    const newDoc: IWedzeeSiteSettings = { ...this.existingDoc, ...this.siteDisabledForm.getRawValue() };
    const isChangingStatus = !this.existingDoc || !!this.existingDoc && newDoc.isSiteDisabled !== this.existingDoc.isSiteDisabled;

    let title: string;
    let msg: string;
    if (isChangingStatus && newDoc.isSiteDisabled) {
      title = 'Disable site?';
      msg = 'The site will be completely locked down and nobody will be able to use it.';
    } else if (isChangingStatus) {
      title = 'Enable site?';
      msg = 'The site will be activated for all users.';
    } else {
      title = 'Change disabled settings?';
      msg = '';
    }


    this.alertSrv.confirm(title, msg).pipe(
      filter(confirmed => confirmed),
      mergeMap(() => this.updateDoc(newDoc)),
      map(() => this.alertSrv.successToast('Disabled settings updated.')),
      take(1)
    ).subscribe();
  }

  saveUpdateRoyaltyFee() {
    const newDoc = { ...this.existingDoc, ...this.royaltyFeeForm.getRawValue() };
    this.alertSrv.confirm('Change royalty fee?', 'All sales will be charged this fee.').pipe(
      filter(confirmed => confirmed),
      mergeMap(() => this.updateDoc(newDoc)),
      map(() => this.alertSrv.successToast('Royalty fee updated.')),
      take(1)
    ).subscribe();
  }

  private updateDoc(doc: IWedzeeSiteSettings): Observable<void> {
    const method = !!this.existingDoc ? 'update' : 'set';
    return fromPromise(this.settingsDocRef[method](doc)).pipe(
      map(() => this.ngOnInit())
    );
  }



}
