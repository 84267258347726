<ng-container *ngIf="!(mainMenuCategories$ | async)">
  <div class="full-width text-center">
    <mat-spinner style="width: 50%;"></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="(mainMenuCategories$ | async) as categories">
  <div class="full-width text-left children-inline-block">
    <h3 class="mat-display-1">Main Menu Categories</h3>
    <button
      style="margin-left: 25px;"
      color="accent"
      mat-raised-button
      (click)="selectMainMenuCategories(categories)"
    >
      Change
    </button>
  </div>
  <div class="full-width text-left">
    <wz-category *ngFor="let c of categories" [doc]="c" [routerLink]="['../..', 'categories', 'edit', c.id]"></wz-category>
  </div>
</ng-container>
