<span
  #tooltip="matTooltip"
  [matTooltip]="matTooltip"
  [matTooltipPosition]="matTooltipPosition"
  (click)="tooltip.toggle();"
  (hover)="tooltip.show();"
  style="overflow: auto; cursor: pointer;"
>
  <ng-content></ng-content>
</span>
