import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ICategory } from 'wz-types/categories';
import { Globals } from '~shared/classes';
import { SelectCategoryComponent } from '~shared/dialogs';
import { AlertService } from '~shared/services';

import { Category } from '../../../classes/category.class';
import { CategoriesService } from '../../../services/categories/categories.service';




@Component({
  selector: 'wza-categories-page',
  templateUrl: './categories-page.component.html',
  styleUrls: ['./categories-page.component.scss']
})
export class CategoriesPageComponent implements OnInit, OnDestroy {
  categoriesWithSubCategories$: Observable<ICategory[]>;
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  numPerPage = 10;

  destroy$: Subject<any> = new Subject();

  isLoading = true;
  allCategories: Category[] = [];
  categoriesDisplayed: Category[] = [];

  paginatorEvent$: Subject<any> = new Subject();

  constructor(
    private categoriesService: CategoriesService,
    private dialog: MatDialog,
    private matSnackbar: MatSnackBar,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    this.categoriesService.getAllCategoriesWithSubcategories().pipe(
      map((cats: Category[]) => {
        this.allCategories = cats;
        this.categoriesDisplayed = cats.slice(0, this.numPerPage);
        this.isLoading = false;
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.paginatorEvent$.pipe(
      map((paginatorEvent: { length: number, pageIndex: number, pageSize: number, previousPageIndex: number }) => {
        const pageIndex = paginatorEvent.pageIndex;
        const pageSize = paginatorEvent.pageSize;
        const startIndex = pageIndex * pageSize;
        this.categoriesDisplayed = this.allCategories.slice(startIndex, startIndex + pageSize);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editSubcategories(editCategory: Category) {
    let isUpdating = false;
    const idsToExclude = [editCategory.id];
    const previouslySelectedIds = editCategory.subCategories.map(c => c.id);
    let selectedSubCats;
    SelectCategoryComponent.open(this.dialog, false, idsToExclude, previouslySelectedIds, `Select subcategories for ${editCategory.name}`).pipe(
      filter((subCats: any) => subCats),
      mergeMap((subCats: Category[]) => {
        selectedSubCats = subCats;
        const doSelectionsHaveParents = subCats.filter(s => !!s.parentCategoryId).length > 0;
        let nextStepObs = () => observableOf(true);
        if (doSelectionsHaveParents) nextStepObs = () => this.alertSrv.confirm(
          'Confirm Change',
          'The subcategories\' current parent categories will be overwritten. Do you want to continue?'
        );
        return nextStepObs();
      }),
      filter((willContinue: boolean) => willContinue),
      mergeMap(() => {
        let nextStep = () => observableOf(undefined);
        if (!!selectedSubCats) {
          isUpdating = true;
          const ids = selectedSubCats.map(s => s.id);
          nextStep = () => editCategory.updateSubcategories(ids);
        }
        return nextStep();
      }),
      take(1),
      filter(res => isUpdating),
      map(() => {
        this.alertSrv.successToast(`Changed subcategories for ${editCategory.name}`);
        this.ngOnInit();
      })
    ).subscribe();
  }

  deleteCategory(id: string) {
    return this.alertSrv.confirm('Delete category?', 'Deleted categories cannot be recovered.').pipe(
      filter((willDelete: boolean) => !!willDelete),
      mergeMap(() => {
        Globals.startLoading();
        return this.categoriesService.deleteCategory(id);
      }),
      map(() => {
        Globals.stopLoading();
        this.matSnackbar.open(`Category deleted`, 'Ok', {
          duration: 2000
        });
        this.ngOnInit();
      }),
      catchError((error: any) => {
        Globals.logError(error, 'ERROR DELETING CATEGORY IN COMPONENT');
        return observableOf();
      })
    ).subscribe();
  }
}
