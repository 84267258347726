<h2>Impersonate User</h2>
<form [formGroup]="emailForm">
  <mat-form-field>
    <mat-label>User email</mat-label>
    <input
      type="search"
      matInput
      placeholder="Email"
      formControlName="email"  
    >
  </mat-form-field>
  <button mat-flat-button [disabled]="emailForm.invalid" (click)="signInAsUser()">
    Sign in as user
  </button>
</form>

<div *ngIf="signInUrl">
  <a 
    style="text-decoration: underline;"
    target="_blank"
    [href]="signInUrl"
  >
    Sign in as {{signInEmail}}
  </a>
  <br><br>
  <a
    style="text-decoration: underline;"
    target="_blank"
    [href]="localUrl">
    Sign in locally as {{signInEmail}} (only for development)
  </a>
</div>
