import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Globals } from '~shared/classes';

@Component({
  selector: 'app-reset-database-page',
  templateUrl: './reset-database-page.component.html',
  styleUrls: ['./reset-database-page.component.scss']
})
export class ResetDatabasePageComponent implements OnInit {
  options = [];
  selectedOption = '';
  isResetting = false;
  isDone = false;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    this.options = await this.http.get(`${Globals.environment.apiUrl}dev/backup-file-options`).toPromise() as string[];
  }

  onSubmit() {
    const url = `${Globals.environment.apiUrl}dev/reset-from-backup/${this.selectedOption}`;
    this.isResetting = true;
    this.http.get(url).subscribe(() => {
      this.isResetting = false;
      this.isDone = true;
    });
  }

}
