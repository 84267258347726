import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// import 'quill-mention';
// import ImageResize from 'quill-image-resize-module';


// add image resize module
// Quill.register('modules/imageResize', ImageResize);

// add mention module
// override p with div tag
const Parchment = Quill.import('parchment');
const block = Parchment.query('block');

block.tagName = 'DIV';
// or class NewBlock extends Block {}; NewBlock.tagName = 'DIV';
Quill.register(block /* or NewBlock */, true);

// import Counter from './counter';
// Quill.register('modules/counter', Counter)

// Add fonts to whitelist
// const font = Quill.import('formats/font');
// We do not add Aref Ruqaa since it is the default
// font.whitelist = ['mirza', 'aref', 'sans-serif', 'monospace', 'serif', 'raleway'];
// Quill.register(font, true);

@Component({
  selector: 'wz-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit, OnChanges {
  @ViewChild('editor') editor: QuillEditorComponent;
  @Input() placeholder = 'Enter text here...';
  @Input() value: string;
  @Output() updated: EventEmitter<string> = new EventEmitter();
  textForm: FormGroup;

  selectionChanged: Subject<any> = new Subject();

  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote'],
      // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': ['Raleway', 'sans-serif'] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      ['link', 'image']                         // link and image, video
    ]
  };

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.selectionChanged.pipe(
      filter((event: any) => {
        return event.range === null &&
        !!event.editor && event.editor.root &&
        event.editor.root.innerHTML !== this.value;
      }),
      map((changeEvent: any) => {
        this.updated.emit(changeEvent.editor.root.innerHTML);
      })
    ).subscribe();

    this.textForm = this.formBuilder.group({
      value: [this.value]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.textForm && !!changes.value) this.textForm.get('value').setValue(changes.value.currentValue);
  }

}
