<div style="text-align: left;">
  <h2 class="mat-headline">
    Simulate Shipment Tracking
  </h2>

  <form *ngIf="!!trackForm" [formGroup]="trackForm">
    <div style="display: inline-block;">
      <mat-label>Order ID Method</mat-label><br>
      <mat-radio-group (change)="orderSelectMethod = $event.value">
        <mat-radio-button [checked]="orderSelectMethod === 'type'" value="type">
          Type
        </mat-radio-button>
        <br>
        <mat-radio-button [checked]="orderSelectMethod === 'select'" value="select">
          Select
          </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field [ngSwitch]="orderSelectMethod">
      <mat-label>Order ID</mat-label>
      <input *ngSwitchCase="'type'" formControlName="orderId" matInput>
      <mat-select *ngSwitchCase="'select'" formControlName="orderId">
        <mat-option *ngFor="let option of orderIdOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Listing ID</mat-label>
      <mat-select formControlName="listingId">
        <mat-option *ngFor="let option of listingIdOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Shippo Status</mat-label>
      <mat-select formControlName="shippoStatus">
        <mat-option *ngFor="let option of statusOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <button mat-flat-button color="secondary" [disabled]="trackForm.invalid" (click)="onSubmit()">
      Send Tracking Event
    </button>
  </form>

  <div *ngIf="shippingResponse">{{ shippingResponse | json }}</div>

</div>
