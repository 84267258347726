import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ISiteEvent } from 'wz-types/logging';

@Component({
  selector: 'app-site-events',
  templateUrl: './site-events.component.html',
  styleUrls: ['./site-events.component.scss']
})
export class SiteEventsComponent implements OnInit {
  events: ISiteEvent[] = [];

  constructor(
    private firebaseDb: AngularFirestore
  ) { }

  async ngOnInit() {
    this.events = await this.firebaseDb.collection('siteEvents').ref
      .orderBy('timestamp', 'desc')
      .limit(2000)
      .get()
      .then((querySnap: any) => querySnap.docs.map(d => d.data())) as any;
  }

}
