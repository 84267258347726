<div class="page-header">
  <span class="mat-display-2" style="display: inline-block;">Terms of Service</span>
  <button (click)="saveChanges()" [disabled]="!isEdited" mat-raised-button color="accent" style="float: right">
    <mat-icon>done</mat-icon>
    Save
  </button>
</div>

<div class="full-width" style="text-align: left;">
  <mat-hint *ngIf="lastUpdatedByEmail" style="font-style: italic;">Last updated by {{lastUpdatedByEmail}} on {{lastUpdatedDate | date}}</mat-hint>
  <br><br>
</div>

<div class="full-width text-center">
  <wz-text-editor [value]="termsHtml" placeholder="Enter terms of service..." (updated)="termsUpdated($event)"></wz-text-editor>
</div>

