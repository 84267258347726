import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PageEvent } from '@angular/material/paginator';
import { MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, combineLatest, forkJoin, from as fromPromise, of as observableOf, Subject } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { FirestoreRefs, User } from '~shared/classes';
import { AlertService } from '~shared/services';

@Component({
  selector: 'wz-people-page',
  templateUrl: './people-page.component.html',
  styleUrls: ['./people-page.component.scss']
})
export class PeoplePageComponent implements OnInit, OnDestroy {
  @ViewChild('userTabs') userTabs: MatTabGroup;
  users: User[];
  usersDisplayed: User[];
  destroy$: Subject<void> = new Subject();
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  isSearching: boolean;
  tabSelected$: BehaviorSubject<string> = new BehaviorSubject('');

  isLoading: boolean;

  defaultPageSize = 20;
  paginator = {
    length: undefined,
    pageSize: 20,
    pageSizeOptions: [10, 20, 30, 50],
    pageIndex: 0
  };

  paginatorEvent$: Subject<PageEvent> = new Subject();


  roleCapabilities = {
    consumer: [
      'Purchase items',
      'Post items for sale'
    ],
    admin: [
      'Change consumer account information',
    ],
    superadmin: [
      'Change any account\'s information, including admins and other superadmins'
    ]
  };

  userRoles: string[] = [];


  constructor(
    private firestore: AngularFirestore,
    private alertSrv: AlertService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.userRoles = Object.keys(this.roleCapabilities);
    this.userRoles.push('Seller');

    let lastTabSelected: string;
    let lastNumOfUsers: number;
    combineLatest([
      this.tabSelected$.pipe(filter(t => !!t)),
      this.paginatorEvent$,
    ]).pipe(
      filter((events: [string, any]) => {
        const selectedSearchTab = events[0] === 'search results';
        if (selectedSearchTab) this.searchText$.next(this.searchText$.value);
        return !selectedSearchTab;
      }),
      mergeMap((events: [string, any]) => {
        this.isLoading = true;
        window.scrollTo(0, 0);
        let getNumOfUsers = () => User.getNumberOfUsers(this.http, events[0]);
        const selectedTabName = events[0];
        if (lastTabSelected === selectedTabName) getNumOfUsers = () => observableOf(lastNumOfUsers);
        return forkJoin([getNumOfUsers(), observableOf(selectedTabName), observableOf(events[1])]);
      }),
      map((results: [number, string, { length: number; pageIndex: number; pageSize: number; previousPageIndex: number; }]) => {
        const numOfUsers = results[0];
        const selectedTabName = results[1];
        const paginatorEvent = results[2];
        if (selectedTabName !== lastTabSelected) {
          this.paginator.pageIndex = 0;
          paginatorEvent.pageIndex = 0;
          this.paginator.pageSize = this.defaultPageSize;
          paginatorEvent.pageSize = this.defaultPageSize;
        }
        lastTabSelected = selectedTabName;
        lastNumOfUsers = numOfUsers;
        this.paginator.length = numOfUsers;
        this.updateByTabAndPagination(selectedTabName, paginatorEvent.pageSize, paginatorEvent.pageIndex);
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.searchText$.pipe(
      filter(r => !!r || this.isSearching),
      debounceTime(250),
      map((searchText: string) => {
        if (!!searchText) {
          this.isSearching = true;
          this.userTabs.selectedIndex = 3;
          this.updateBySearchText(searchText);
        } else {
          this.isSearching = false;
          this.userTabs.selectedIndex = 0;
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.tabSelected$.next('consumer');
    this.paginatorEvent$.next({
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      previousPageIndex: 0
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  updateByTabAndPagination(role: string, pageSize: number, pageIndex: number): void {
    let firebaseQuery;
    
    if (role === 'seller') {
      firebaseQuery = FirestoreRefs.users.ref
      .where('hasAcknowledgedSellerAgreement', '==', true)
      .orderBy('lastLoginDate', 'desc')
      .limit(pageSize);
    } else {
      firebaseQuery = FirestoreRefs.users.ref
      .where('role', '==', role)
      .orderBy('lastLoginDate', 'desc')
      .limit(pageSize);
    }

    const lastLastLoginDateDisplayed = !!this.usersDisplayed && this.usersDisplayed.length > 0 ? this.usersDisplayed[this.usersDisplayed.length - 1].lastLoginDate : undefined;
    if (pageIndex > 0) firebaseQuery = firebaseQuery.startAfter(lastLastLoginDateDisplayed);

    this.usersDisplayed = [];
    fromPromise(firebaseQuery.get()).pipe(
      map((usersQuerySnapshot: firebase.firestore.QuerySnapshot) => {
        this.usersDisplayed = usersQuerySnapshot.docs.map((d) => new User(this.http, undefined, this.firestore, undefined, undefined, undefined, undefined, undefined, <any>d.data()));
        this.isLoading = false;
      }),
      take(1)
    ).subscribe();
  }


  updateBySearchText(searchText: string): void {
    this.isLoading = true;
    fromPromise(FirestoreRefs.users.ref
      .orderBy('email')
      .limit(10)
      .startAt(searchText)
      .endAt(searchText + '\uf8ff').get()).pipe(
        map((snapshot: firebase.firestore.QuerySnapshot) => {
          this.usersDisplayed = snapshot.docs.map(d => new User(this.http, undefined, this.firestore, undefined, undefined, undefined, undefined, undefined, <any>d.data()));
          this.isLoading = false;
        }),
        take(1)
      ).subscribe();
  }

}
