import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { IListing } from 'wz-types/listings';
import { IWedzeeOrder } from 'wz-types/orders';
import { IUser } from 'wz-types/user';
import { FirestoreRefs, Globals } from '~shared/classes';
import { AlertService } from '~shared/services';
import { OrdersStore } from '~shared/stores';

@Component({
  selector: 'wz-listing-detail-page',
  templateUrl: './listing-detail-page.component.html',
  styleUrls: ['./listing-detail-page.component.scss']
})
export class ListingDetailPageComponent implements OnInit {
  listingId: string;
  listing$: Observable<IListing>;
  categoriesLookup = Globals.categoriesLookup;
  colorsLookup = Globals.colors.reduce((p, c) => <any>{ ...p, [c.id]: c }, {});
  seller$: Observable<IUser>;
  descMaxChars = 200;
  currentDescChars = this.descMaxChars;
  consumerSiteUrl = Globals.environment.siteUrl;
  orders$: Observable<IWedzeeOrder[]>;
  canDelete$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private ordersStore: OrdersStore,
    private alertSrv: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.listingId = this.route.snapshot.params.listingId;
    this.canDelete$ = Globals.user.SellerAccount.canDeleteListing(this.listingId);
    this.orders$ = this.ordersStore.dispatch('getOrdersByListingId', this.listingId);
    this.listing$ = FirestoreRefs.listings.doc(this.listingId).valueChanges().pipe(
      tap((listing: IListing) => {
        this.seller$ = FirestoreRefs.users.doc(listing.sellerId).valueChanges() as any;
      })
    ) as any;
  }

  getColorIds(listing: IListing): string[] {
    return [listing.primaryColorId, ...listing.colorIds];
  }

  openOrderDetail(order: IWedzeeOrder) {
    window.open(`${this.consumerSiteUrl}/order-details/${order.id}/${order.billToAddressZip}`, '_blank');
  }

  deleteListing() {
    this.alertSrv.confirm('Delete Listing?', 'This listing will be unrecoverable and completely removed from the site.').pipe(
      filter(willDelete => willDelete),
      mergeMap(() => Globals.user.SellerAccount.deleteListing(this.listingId)),
      map(() => {
        this.router.navigateByUrl('listings');
        this.alertSrv.successToast('Listing deleted');
      }),
      take(1)
    ).subscribe();
  }

  deleteTooltip(canDelete: boolean): string {
    return !canDelete ? 'You cannot delete this listing because there are orders or disputes pending on it.' : '';
  }

}
