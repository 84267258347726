<ng-container *ngIf="getGlobals() as globals">
  <wz-navbar *ngIf="isUserInitialized"></wz-navbar>
  <ng-container *ngIf="getUser() as user">

    <div *ngIf="!!globals.isInitialUserLoaded && globals.loader.isLoading" class="loading-container">
      <h1 style="color: white; font-weight: bold; opacity: 1">{{globals.loader.message}}</h1>
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>

    <ng-container *ngIf="user && user.isLoggedIn()">
        <mat-drawer-container autosize *ngIf="user.isAdmin()">
          <mat-drawer *ngIf="!!user && !!user.isInitialized" #wzaDrawer mode="side" opened style="width: 250px; background-color: #F5F5F5">
              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>Home Page</div>
                <a mat-list-item mat-action-row matLine routerLink="home/main-menu-categories" routerLinkActive="active-menu-item" class="menu-item">
                  Main menu categories
                </a>
                <a mat-list-item mat-action-row matLine routerLink="home/call-to-action" routerLinkActive="active-menu-item" class="menu-item">
                  Call to action
                </a>
                <!-- <a mat-list-item mat-action-row matLine routerLink="home/trust-icons" routerLinkActive="active-menu-item" class="menu-item">
                  Trust icons
                </a> -->
              </mat-nav-list>

              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>Content</div>
                <a mat-list-item mat-action-row matLine routerLink="/about" routerLinkActive="active-menu-item" class="menu-item">
                  About page
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/sell-on-wedzee" routerLinkActive="active-menu-item" class="menu-item">
                  Sell on Wedzee
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/faq" routerLinkActive="active-menu-item" class="menu-item">
                  FAQ
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/categories" routerLinkActive="active-menu-item" class="menu-item">
                  Categories
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/wedding-colors" routerLinkActive="active-menu-item" class="menu-item">
                  Wedding Colors
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/orders" routerLinkActive="active-menu-item" class="menu-item">
                  Orders
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/listings" routerLinkActive="active-menu-item" class="menu-item">
                  Listings
                </a>
              </mat-nav-list>

              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>Terms & Policies</div>
                <a mat-list-item mat-action-row matLine routerLink="/terms-of-service" routerLinkActive="active-menu-item" class="menu-item">
                  Terms of Service
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/privacy-policy" routerLinkActive="active-menu-item" class="menu-item">
                  Privacy Policy
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/seller-agreement" routerLinkActive="active-menu-item" class="menu-item">
                  Seller Agreement
                </a>
              </mat-nav-list>


              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>Users</div>
                <a mat-list-item mat-action-row matLine routerLink="/people" routerLinkActive="active-menu-item" class="menu-item">
                  People
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/disputes" routerLinkActive="active-menu-item" class="menu-item">
                  Disputes
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/impersonate" routerLinkActive="active-menu-item" class="menu-item">
                  Impersonate User
                </a>
              </mat-nav-list>

              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>General</div>
                <a mat-list-item mat-action-row matLine routerLink="/settings" routerLinkActive="active-menu-item" class="menu-item">
                  Settings
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/site-events" routerLinkActive="active-menu-item" class="menu-item">
                  Site Events
                </a>
                <a mat-list-item mat-action-row matLine routerLink="/test-shipment-tracking" routerLinkActive="active-menu-item" class="menu-item">
                  Simulate Shipment Tracking
                </a>
                <a *ngIf="!isProd" mat-list-item mat-action-row matLine routerLink="/reset-database" routerLinkActive="active-menu-item" class="menu-item">
                  Reset Database
                </a>
              </mat-nav-list>

              <mat-nav-list dense class="no-margin no-padding">
                <div mat-list-divider matSubheader>Developer</div>
                <a mat-list-item mat-action-row matLine routerLink="/exceptions" routerLinkActive="active-menu-item" class="menu-item">
                  Exceptions
                </a>
              </mat-nav-list>
          </mat-drawer>

          <div class="main-router-outlet-container">
            <div class="page-container">
              <router-outlet></router-outlet>
            </div>
          </div>
        </mat-drawer-container>

        <div *ngIf="!!user && !user.isAdmin()" class="main-router-outlet-container">
          <div class="page-container">
            <h3 style="margin-top: 20vh!important;">Sorry! You don't have permission to access this site. If you should have permission, please contact <br><a [href]="'mailto:' + globals.supportEmail">{{globals.supportEmail}}</a></h3>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!!user && !user.isLoggedIn()" class="main-router-outlet-container">
        <div class="page-container">
          <button (click)="signIn()" color="accent" style="margin-top: 20vh!important; font-size: x-large" mat-raised-button>Sign in to continue</button>
        </div>
      </div>
    </ng-container>
  </ng-container>



<wz-footer *ngIf="isUserInitialized"></wz-footer>
