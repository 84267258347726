import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { SelectCategoryComponent } from '~shared/dialogs';

import { CategoriesService } from '../../services/categories/categories.service';
import { Category } from './../../classes/category.class';

@Component({
  selector: 'wz-main-menu-categories-page',
  templateUrl: './main-menu-categories-page.component.html',
  styleUrls: ['./main-menu-categories-page.component.scss']
})
export class MainMenuCategoriesPageComponent implements OnInit {
  mainMenuCategories$: Observable<Category[]>;

  constructor(
    private dialog: MatDialog,
    private categoriesSrv: CategoriesService
  ) { }

  ngOnInit() {
    this.mainMenuCategories$ = this.categoriesSrv.getMainMenuCategories();
  }

  selectMainMenuCategories(mainMenuCats: any[]): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: { previouslySelectedIds: mainMenuCats.map(cat => cat.id) }
    });

    dialogRef.afterClosed().pipe(
      mergeMap((categories: Category[]) => {
        const catIds = categories.map(c => c.id);
        return this.categoriesSrv.setMainMenuCategories(catIds);
      }),
      map(() => this.updateMainMenuCategories()),
      take(1)
    ).subscribe();
  }

  updateMainMenuCategories(): void {
    const self = this;
    self.ngOnInit();
  }


}
