<div class="full-width text-left children-inline-block">
  <h3 class="mat-display-1">Items</h3>
  <div style="float: right; width: 100px;">
    <button mat-icon-button (click)="paginateListings('left')">
      <mat-icon>
        keyboard_arrow_left
      </mat-icon>
    </button>
    <button mat-icon-button (click)="paginateListings('right')">
      <mat-icon>
        keyboard_arrow_right
      </mat-icon>
    </button>
  </div>
</div>

<div class="full-width">
  <mat-form-field ngStyle.gt-md="margin-left: 8px;" style="width: 95%; display: block;">
  <mat-label>Search by id</mat-label>
  <input type="search" matInput placeholder="Search your items" (keyup)="searchText$.next($event.target.value)">
  <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div>
  <wz-product *ngFor="let l of listings" [doc]="l" [isAdminSite]="true" [routerLink]="[l.id]"></wz-product>
</div>
