import { Component, OnInit } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IPolicy } from 'wz-types/pages-policies';
import { AlertService, PagesPoliciesService } from '~shared/services';



@Component({
  selector: 'wz-privacy-policy-editor-page',
  templateUrl: './privacy-policy-editor-page.component.html',
  styleUrls: ['./privacy-policy-editor-page.component.scss']
})
export class PrivacyPolicyEditorPageComponent implements OnInit {
  isLoading: boolean;
  isEdited: boolean;
  lastUpdatedDate: number;
  lastUpdatedByEmail: string;
  termsHtml: string;

  constructor(
    private pagesPoliciesSrv: PagesPoliciesService,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.pagesPoliciesSrv.getPolicy('privacyPolicy').pipe(
      map((policyDoc: IPolicy) => {
        if (!!policyDoc) {
          this.isEdited = false;
          this.termsHtml = policyDoc.html;
          this.lastUpdatedDate = <any>policyDoc.lastUpdatedDate;
          this.lastUpdatedByEmail = policyDoc.lastUpdatedBy.email;
        }
        this.isLoading = false;
      })
    ).subscribe();

  }

  termsUpdated(newHtml): void {
    this.isEdited = true;
    this.termsHtml = newHtml;
  }

  saveChanges() {
    this.alertSrv.confirm('Submit changes?', 'All changes will go live on the site immediately.').pipe(
      filter((didConfirm: boolean) => didConfirm),
      mergeMap(() => this.pagesPoliciesSrv.savePolicy('privacyPolicy', this.termsHtml)),
      map(() => {
        this.alertSrv.successToast('Privacy policy has been updated!');
        this.ngOnInit();
      })
    ).subscribe();
  }

}
