<mat-card style="margin-bottom: 10px;">
  <ng-container *ngIf="isSellerPage && !item.isCancelled && item.trackingStatus === 'processing'">
    <mat-menu #itemMoreMenu>
      <button (click)="cancelItem()" mat-menu-item>
        <mat-icon>warning</mat-icon>
        Cancel this item
      </button>
    </mat-menu>
    <button style="position: absolute; right: 5px; top: 5px;" (click)="$event.stopPropagation()" [matMenuTriggerFor]="itemMoreMenu"
      mat-icon-button matTooltip="More">
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-container>

  <mat-card-title>
    <ng-container *ngIf="!isSellerPage">
    <div [matMenuTriggerFor]="actionMenu" *ngIf="showActionButtons" class="show-more"><i fxFlex="25px" class="material-icons"> more_vert </i></div>
    <mat-menu #actionMenu>
      <span [matTooltip]="getDisputeTooltip(item)" matTooltipPosition="before">
        <button mat-menu-item [routerLinkAbsolute]="'dispute/' + order.id + '/' + order.billToAddressZip + '/' + listing.id"
        (click)="$event.stopPropagation()" [disabled]="!item.canDispute || item.disputeId">
        File a Dispute
      </button>
    </span>
    <span [matTooltip]="getCancelTooltip()" matTooltipPosition="before">
      <button mat-menu-item [disabled]="!buyerCanCancel" (click)="$event.stopPropagation(); cancelItem()">
        Cancel Item
      </button>
    </span>
    </mat-menu>
  </ng-container>

    <div fxLayout="row">
      <img fxFlex="0 0 95px" class="cart-item-img padding-right" [src]="listing.thumbnailImg" alt="">
      <div fxFlex="calc(100% - 95px)" style="align-self:center; margin-top: -13px;">
        <div class="order-item-name no-margin" style="padding-bottom: 3px;">
          <span *ngIf="!!item.isCancelled" style="color: red;">(Cancelled) </span>
          <br *ngIf="!!item.isCancelled">
          <div fxShow.gt-sm fxHide.lt-md>{{listing.name | maxChars:28 }}</div>
          <div fxShow.lt-md fxHide.gt-sm>{{listing.name | maxChars:14 }}</div>
        </div>
        <mat-card-subtitle class="no-margin">{{item.purchasedPrice | currency}} x {{item.qty}}</mat-card-subtitle>
        <div style="font-size: 1em;">{{formatTracking(item.trackingStatus)}}
          <wz-tooltip (click)="$event.stopPropagation()" [matTooltip]="trackingTooltip(item.trackingStatus)">
            <mat-icon style="font-size: 1.2em; margin-top: 5px;">help</mat-icon>
          </wz-tooltip>
        </div>
      </div>
      </div>
  </mat-card-title>
  <div *ngIf="!!item.disputeId"  style="color: red;">
    <br>
    <mat-icon color="warn">error_outline</mat-icon>
    Dispute in progress
  </div>
  <div *ngIf="!!item.customizationNotes">
    <br>
    <mat-form-field appearance="outline" disabled style="width: 100%;">
      <mat-label>Customization notes</mat-label>
      <textarea matInput disabled style="height: 55px;" [value]="item.customizationNotes"> </textarea>
    </mat-form-field>
  </div>
  <div *ngIf="!!item && !!labels && labels.length > 0">
    <br>
    <div style="padding-bottom: 3px;">Item Tracking Numbers</div>
    <a mat-list-item *ngFor="let l of labels" [href]="l.tracking_url_provider" target="_blank" style="color: gray;">
      {{l.tracking_number}}
    </a>
  </div>
  <div *ngIf="!labels || labels.length === 0">
    No tracking information available yet for this item.
  </div>
</mat-card>
