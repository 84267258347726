import { Pipe, PipeTransform } from '@angular/core';
import * as beautify from 'beautify';

@Pipe({
  name: 'prettyJson'
})
export class PrettyJsonPipe implements PipeTransform {

  transform(val, isAlreadyStringified: boolean) {
    return beautify(isAlreadyStringified ? val : JSON.stringify(val), { format: 'json' });
  }

}
