<div class="page-header">
  <span class="mat-display-2" style="display: inline-block;">Seller Agreement</span>
  <button (click)="saveChanges()" [disabled]="!isEdited || !termsHtml" mat-raised-button color="accent" style="float: right">
    <mat-icon>done</mat-icon>
    Save
  </button>
</div>

  <div class="full-width" style="text-align: left;">
    <mat-hint *ngIf="lastUpdatedByEmail" style="font-style: italic;">Last updated by {{lastUpdatedByEmail}} on {{lastUpdatedDate | date}}</mat-hint>
    <br><br>
  </div>

  <div class="full-width text-center">
    <wz-text-editor [value]="termsHtml" placeholder="Enter seller agreement..." (updated)="termsUpdated($event)"></wz-text-editor>
  </div>
