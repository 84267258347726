<div class="add-img-wrapper" [style.max-width]="(widthPx + 10) + 'px'">
  <div
    class="add-img-container"
    [style.width]="widthPx + 'px'"
    [style.height]="imgHeight"
    [style.background]="!!uploadedImgUrl ? 'url(' + uploadedImgUrl + ')' : ''"
  >
    <div *ngIf="!!uploadedImgUrl" class="indicator edit" (click)="imageClick($event)">
      <mat-icon>zoom_in</mat-icon>
    </div>


    <div *ngIf="!!uploadedImgUrl && hasIndex && index > 0" class="indicator left-arrow" (click)="imgChangeEvents.emit('moveLeft')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>

    <div *ngIf="!!uploadedImgUrl && hasIndex && index !== listLength - 1" class="indicator right-arrow" (click)="imgChangeEvents.emit('moveRight')">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>

    <div *ngIf="!!uploadedImgUrl && hasIndex" class="indicator remove" (click)="imgChangeEvents.emit('remove')">
      <mat-icon>delete</mat-icon>
    </div>

    <mat-spinner [diameter]="widthPx - 30" *ngIf="isUploading | async" color="accent"></mat-spinner>
    <img
      *ngIf="!(isUploading | async) && !uploadedImgUrl"
      class="placeholder"
      [src]="imgBackground"
      [style.top]="background.top"
      [style.width]="background?.width"
      [style.left]="background.left"
    >
  </div>
  <button
    (click)="openFileInput()"
    class="add-img-btn"
    [style.width]="widthPx + 'px'"
    color="accent"
    [disabled]="disabled"
    mat-raised-button
    [ngSwitch]="!!uploadedImgUrl"
  >
    <span *ngSwitchCase="false">
      Add image
    </span>
    <mat-icon *ngSwitchCase="true">autorenew</mat-icon>
  </button>
  <input 
    *ngFor="let inputId of inputIds"
    [id]="inputId" 
    class="wedzee-img-input cdk-visually-hidden"
    accept="image/*"
    [style.visibility]="isCypress ? 'visible' : 'hidden'"
    type="file"
    (change)="fileChange($event)"
  >
</div>

