
<mat-dialog-content>
  <div class="full-width children-inline-block">
    <h3 style="display: inline-block;" mat-dialog-title>{{ isEditing ? 'Edit ' : 'Add ' + 'wedding color' }}</h3>
    <button style="float: right!important;" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="colorForm">
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="Name" type="text">
      <mat-error *ngIf="!colorForm.get('name').valid">Name is required!</mat-error>
    </mat-form-field>

    <div class="full-width children-inline-block" style="min-width: 300px;">
      <div style="width: 48%; min-width: 275px; vertical-align: top;">
        <h4 class="mat-subheading-2">Select a color</h4>
        <span [style.background]="selectedColor"
          [cpToggle]="true"
          [cpDialogDisplay]="'inline'"
          [cpCancelButton]="false"
          [cpOutputFormat]="'hex'"
          [(colorPicker)]="selectedColor"
          (click)="colorForm.markAsTouched()"
        >
        </span>
      </div>
      <div style="width: 48%; min-width: 275px; vertical-align: top;">
        <h4 class="mat-subheading-2">Default image</h4>
        <wz-add-image (imgAdded)="imgAdded($event)" [originalImgUrl]="originalImgUrl" [aspectRatio]="{ width: 3, height: 2 }" widthPx="200"></wz-add-image>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="colorForm.invalid || !colorForm.touched" (click)="submit()" mat-flat-button color="accent">Save</button>
  <button style="margin-left: 10px;" mat-stroked-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
