import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWedzeeException } from 'wz-types/wedzee';

@Component({
  selector: 'wz-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})
export class ExceptionComponent implements OnInit {
  @Output() delete: EventEmitter<string> = new EventEmitter();
  @Input() e: IWedzeeException;


  constructor() { }

  ngOnInit() {
  }

}
