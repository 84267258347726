<div class="page-header">
  <span class="mat-display-3">People</span>
</div>

<div class="full-width">
  <mat-form-field class="full-width">
    <mat-label>Search by email</mat-label>
    <input type="search" matInput placeholder="Search" (keyup)="searchText$.next($event.target.value.toLowerCase())">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>


<mat-tab-group #userTabs color="accent" (selectedTabChange)="tabSelected$.next($event.tab.textLabel.toLowerCase())">
  <mat-tab *ngFor="let role of userRoles" [label]="role.toUpperCase()" style="text-align: center!important;">
    <div *ngIf="isLoading" class="full-width text-center" style="padding-top: 15vh!important; padding-bottom: 15vh!important;">
      <mat-spinner style="margin: auto!important;" color="accent"></mat-spinner>
    </div>
    <span *ngIf="!isLoading && (!usersDisplayed || (!!usersDisplayed && usersDisplayed.length === 0))" class="not-found-text">No {{role}} users</span>
    <wz-user-table *ngIf="!!usersDisplayed && usersDisplayed.length > 0 && !isLoading" (updated)="tabSelected$.next(role.toLowerCase())" [users]="usersDisplayed" [roleCapabilities]="roleCapabilities" [userRoles]="userRoles"></wz-user-table>
    <mat-paginator
      [length]="paginator.length"
      [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions"
      (page)="paginatorEvent$.next($event)">
    </mat-paginator>
  </mat-tab>

  <mat-tab *ngIf="isSearching" label="SEARCH RESULTS" style="text-align: center!important;">
    <div *ngIf="isLoading" class="full-width text-center" style="padding-top: 15vh!important; padding-bottom: 15vh!important;">
      <mat-spinner style="margin: auto!important;" color="accent"></mat-spinner>
    </div>
    <span *ngIf="!usersDisplayed || (!!usersDisplayed && usersDisplayed.length === 0)" class="not-found-text">No results for '{{searchText$ | async}}'</span>
    <wz-user-table *ngIf="!!usersDisplayed && usersDisplayed.length > 0" (updated)="searchText$.next(searchText$.value)" [users]="usersDisplayed" [roleCapabilities]="roleCapabilities" [userRoles]="userRoles"></wz-user-table>
  </mat-tab>
</mat-tab-group>
