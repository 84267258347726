<div class="full-width text-left">
  <div class="mat-headline">
    Orders
  </div>
</div>

<div clas="full-width text-left">
  <div class="mat-title no-margin no-padding text-left">Search by</div> 
  <mat-radio-group class="text-left full-width" (change)="searchBy$.next($event.value)">
    <mat-radio-button *ngFor="let s of searchByOptions" class="margin" [checked]="searchBy === s" [value]="s">
      {{s}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="full-width">
  <mat-form-field class="full-width">
      <mat-label>Search orders</mat-label>
      <input type="search" matInput placeholder="Search your items" (keyup)="searchText$.next($event.target.value.toLowerCase())" autocomplete="off" >
      <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<div *ngIf="orders?.length === 0 && !isLoading" class="full-width text-center" style="padding: 40px;">
  <div class="not-found-text">No orders found</div>
</div>

<ng-container>


  <mat-accordion *ngFor="let o of orders; let i = index">
    <mat-expansion-panel (opened)="o.hasOpened = true;">
      <mat-expansion-panel-header>
        <mat-panel-title style="font-family: sans-serif!important;">
            #{{o.id | orderId}}
        </mat-panel-title>
        <mat-panel-description>
            {{o.createdTimestamp | date:'short'}}
            <a style="margin-top: -10px;" [href]="url + '/order-details/' + o.id + '/' + o.billToAddressZip" target="_blank" mat-icon-button matTooltip="View this order on the consumer site">
              <mat-icon>open_in_new</mat-icon>
            </a>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <wz-admin-order-detail *ngIf="!!o.hasOpened" [orderId]="o.id"></wz-admin-order-detail>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-paginator 
    [pageSize]="20"
    [length]="numOfResults"
    [pageSizeOptions]="[20, 40, 60]"
    (page)="pagination$.next([$event, orders])">
  </mat-paginator>
</ng-container>

