import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { IWedzeeOrder } from 'wz-types/orders';
import { TOrderSearchByOption } from 'wz-types/orders.d';
import { Globals } from '~shared/classes';
import { OrdersStore } from '~shared/stores';

@Component({
  selector: 'wz-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss']
})
export class OrdersPageComponent implements OnInit, OnDestroy {
  private pages: [];
  orders: IWedzeeOrder[] = [];
  pagination$: BehaviorSubject<[PageEvent, IWedzeeOrder[]]> = new BehaviorSubject(<any>[undefined, undefined]);
  destroy$ = new Subject();
  numOfResults = 0;
  searchByOptions = ['Order number', 'Seller ID', 'Buyer ID', 'Listing ID', 'Shippo Label ID', 'Stripe ID', 'Paypal ID', 'All properties'];
  searchBy$: BehaviorSubject<TOrderSearchByOption> = new BehaviorSubject(<any>'Order number');
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  url = Globals.environment.siteUrl;

  isLoading = false;
  private searchText = '';
  
  constructor(
    private ordersStore: OrdersStore
  ) { }

  ngOnInit() {
    this.updateOrders();
    this.pagination$.pipe(
      map((r: [PageEvent, IWedzeeOrder[]]) => {
        window.scrollTo(0, 0);
        if (r[0] === undefined) {
          return;
        }
        this.orders = this.pages[r[0].pageIndex];
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.searchBy$.pipe(
      map((text: string) => this.search(this.searchText$.value)),
      takeUntil(this.destroy$)
    ).subscribe();

    this.searchText$.pipe(
      debounceTime(1000),
      map((text: string) => this.search(text)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(text) {
    if (text === this.searchText) {
      return;
    }
    
    this.searchText = text;

    if (text && text.length > 0) {
      this.isLoading = true;
      this.ordersStore.dispatch('searchOrders', this.searchBy$.value, text).pipe(
        map(res => {
          this.orders = res;
          this.isLoading = false;
        }),
        take(1)
      ).subscribe();
    } else {
      this.updateOrders();
    }
  }

  updateOrders(itemsPerPage?: number, pageNumber?: number) {
    this.isLoading = true;
    this.ordersStore.dispatch('getAllOrders', itemsPerPage, pageNumber).pipe(
      map((r) => {
        this.pages = r.pages;
        this.orders = r.pages[pageNumber ?? 0];
        this.numOfResults = r.total;
        this.isLoading = false;
      }),
      take(1)
    ).subscribe();
  }

}
