import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay, map, take, takeUntil } from 'rxjs/operators';
import { ICategory, ICategoryWithSubcategories } from 'wz-types/categories';
import { IInitialData } from 'wz-types/page-data';
import { FirestoreRefs, Globals } from '~shared/classes';
import { WzNavbarComponent } from '~shared/components';
import { AuthService } from '~shared/services';

@Component({
  selector: 'wz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('wzaDrawer') wzaDrawer: any;
  isUserInitialized: boolean;
  isProd = Globals.environment.production;

  constructor(
    private router: Router,
    private authSrv: AuthService,
    private firestoreRefs: FirestoreRefs,
    private http: HttpClient
  ) {

  }

  ngOnInit() {
    Globals.isAdminApp = true;

    this.http.get(`${Globals.environment.apiUrl}page-data/init`).pipe(
      take(1),
      map((r: IInitialData) => {
        Globals.initialLoadData = r;
        Globals.siteSettings = r.settings;
        Globals.colors = r.weddingColors;
        const lookup = {};
        const cats = r.categories;
        Globals.categories = cats
          .sort((a, b) => a.name < b.name ? -1 : 1)
          .map((c: ICategoryWithSubcategories) => {
          c.subCategories = <any>cats.filter(cat => cat.parentCategoryId === c.id);
          return c;
        });
        cats.forEach(c => lookup[c.id] = c);
        Globals.categoriesLookup = lookup;
        Globals.menuNavLinks = Globals.categories
          .filter(c => c.isInMainMenu)
          .sort((a: ICategory, b: ICategory) => {
            const isOther = (c) => c.name.indexOf('Other') > -1;
            return (a.name < b.name && !isOther(a)) || isOther(b) ? -1 : 1;
          })
          .map(c => {
            return { name: c.name, routerLink: ['products', 'category', c.id], doc: c };
          });
        Globals.stopLoading();
      })
    ).subscribe();


    Globals.userInstantiated$.pipe(
      take(1),
      map(() => this.isUserInitialized = true)
    ).subscribe();
  }

  ngAfterViewInit() {
    this.router.events.pipe(takeUntil(Globals.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    Globals.userInstantiated$.pipe(
      delay(1500),
    ).subscribe(() => WzNavbarComponent.drawer = this.wzaDrawer);

    setTimeout(() => {
      // TODO: Figure this out. It's been 'changedAfterChecked' error. Hacked around it.
      WzNavbarComponent.drawer = this.wzaDrawer;
    }, 1000);
  }

  ngOnDestroy() {
    Globals.destroy$.next();
    Globals.destroy$.complete();
  }

  getGlobals() {
    return Globals;
  }

  getUser() {
    return Globals.user;
  }

  signIn() {
    return AuthService.presentLoginDialog$.next();
  }

}
