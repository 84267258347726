<div class="page-header">
  <span class="mat-headline">Disputes</span>
</div>

<div *ngIf="isLoading" class="full-width text-center">
  <div class="not-found-text">Getting disputes</div>
  <mat-spinner diameter="75"></mat-spinner>
</div>

<ng-container *ngIf="disputes$ | async as disputes">
  <div *ngIf="!disputes || disputes?.length === 0" class="full-with text-center" style="padding-top: 75px;">
    <div class="not-found-text">No disputes!</div>
  </div>
  <mat-tab-group *ngIf="disputes && disputes.length > 0" (selectedIndexChange)="$event === 2 ? selectedArchiveTab() : undefined">
    <mat-tab label="Unresolved" *ngIf="filterResolved(disputes, false) as ds">
      <wz-dispute-item *ngFor="let d of ds" [dispute]="d" (updated)="ngOnInit()"></wz-dispute-item>
      <div *ngIf="!ds || ds?.length === 0" class="full-width text-center">
          <div style="margin-top: 75px!important;" class="not-found-text">No unresolved disputes!</div>
      </div>
    </mat-tab>
    <mat-tab label="Resolved" *ngIf="filterResolved(disputes, true) as ds">
      <wz-dispute-item *ngFor="let d of ds" [dispute]="d" (updated)="ngOnInit()"></wz-dispute-item>
      <div *ngIf="!ds || ds?.length === 0" class="full-width text-center">
          <div style="margin-top: 75px!important;" class="not-found-text">No resolved disputes!</div>
      </div>
    </mat-tab>
  
    <mat-tab label="Archived">
      <wz-dispute-item *ngFor="let d of archiveDisputes$ | async" [dispute]="d" (updated)="ngOnInit()"></wz-dispute-item>
      <div *ngIf="isLoadingArchivedDisputes" class="full-width text-center">
        <div class="not-found-text">Getting archived disputes</div>
        <mat-spinner diameter="75"></mat-spinner>
      </div>
      <div *ngIf="!isLoadingArchivedDisputes &&(!((archiveDisputes$ | async)) || (archiveDisputes$ | async)?.length === 0)" class="full-width text-center">
          <div style="margin-top: 75px!important;" class="not-found-text">No archived disputes!</div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
