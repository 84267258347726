import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IWeddingColor } from 'wz-types/home-page.d';

import {
  CreateEditWeddingColorComponent
} from '../../dialogs/create-edit-wedding-color/create-edit-wedding-color.component';
import { WeddingColorsService } from '../../services/wedding-colors/wedding-colors.service';

@Component({
  selector: 'wz-wedding-colors-page',
  templateUrl: './wedding-colors-page.component.html',
  styleUrls: ['./wedding-colors-page.component.scss']
})
export class WeddingColorsPageComponent implements OnInit {
  weddingColors$: Observable<IWeddingColor[]>;


  constructor(
    private dialog: MatDialog,
    private weddingColorsSrv: WeddingColorsService
  ) { }

  ngOnInit() {
    this.weddingColors$ = this.weddingColorsSrv.getWeddingColors();
  }

  addColor() {
    const dialogRef = this.dialog.open(CreateEditWeddingColorComponent, {
      data: undefined,
      minWidth: '325px'
    });

    dialogRef.afterClosed().pipe(
      map((didCreateOrEdit: any) => didCreateOrEdit ? this.ngOnInit() : undefined),
      take(1)
    ).subscribe();
  }

  editColor(color: IWeddingColor) {
    const dialogRef = this.dialog.open(CreateEditWeddingColorComponent, {
      data: { colorDoc: color },
      minWidth: '325px'
    });

    dialogRef.afterClosed().pipe(
      map((didCreateOrEdit: any) => didCreateOrEdit ? this.ngOnInit() : undefined),
      take(1)
    ).subscribe();
  }

}
