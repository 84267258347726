import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { ICategory } from 'wz-types/categories';
import { Globals } from '~shared/classes';
import { SelectCategoryComponent } from '~shared/dialogs';
import { AlertService } from '~shared/services';

import { Category } from '../../../classes/category.class';
import { CategoriesService } from '../../../services/categories/categories.service';


@Component({
  selector: 'wza-create-edit-category-page',
  templateUrl: './create-edit-category-page.component.html',
  styleUrls: ['./create-edit-category-page.component.scss']
})
export class CreateEditCategoryPageComponent implements OnInit {
  isEditing: boolean;
  isChanged: boolean;
  categoryId: string;
  category: Category;
  categoryForm: FormGroup;
  isLoading: boolean;
  willHaveGender: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,
    private categoriesSrv: CategoriesService,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    const self = this;
    this.isLoading = true;
    Globals.startLoading();
    this.categoryForm = this.formBuilder.group({
      name: [undefined, Validators.required],
      // description: [undefined],
      imageUrl: [undefined, Validators.required],
      parentCategories: [[]],
      subCategories: [[]],
      tags: [[]],
      isInMainMenu: [false],
      mutuallyExclusiveCategories: [[]],
      gender: [undefined]
    });

    this.categoryId = this.route.snapshot.params.categoryId;
    this.isEditing = !!this.categoryId;
    if (!this.isEditing) {
      this.categoryId = this.firestore.createId();
      this.isLoading = false;
      Globals.stopLoading();
    } else {
      Globals.startLoading();
      this.categoriesSrv.getCategory(this.categoryId).pipe(
        mergeMap((cat: Category) => {
          this.category = cat;
          if ((<any>cat).gender) this.willHaveGender = true;
          const setVal = (formKey: string, docKey?: string) => this.categoryForm.get(formKey).setValue(cat[!!docKey ? docKey : formKey]);
          setVal('name');
          // setVal('description');
          setVal('imageUrl', 'imageUrl');
          setVal('tags');
          setVal('isInMainMenu');
          setVal('gender');
          return forkJoin([this.category.getSubcategories(), observableOf([]), observableOf([])]); // Last two items are remnants of abandoned design.
        }),
        map((results: [Category[], Category[], Category[]]) => {
          const subs = results[0];
          const subCatsControl = this.categoryForm.get('subCategories');
          subCatsControl.setValue(subs);

          const parents = results[1];
          const parentsControl = this.categoryForm.get('parentCategories');
          parentsControl.setValue(parents);

          const mutuals = results[2];
          const mutualsControl = this.categoryForm.get('mutuallyExclusiveCategories');
          mutualsControl.setValue(mutuals);

          Globals.stopLoading();
          this.isLoading = false;
        }),
        take(1),
        catchError((error: any) => {
          Globals.stopLoading();
          Globals.logError(error, 'CAUGHT AN ERROR GETTING CATEGORY: ');
          return observableOf(undefined);
        })
      ).subscribe();
    }
  }

  /**
   *
   */
  submitCategoryForm(): void {
    const self = this;
    Globals.startLoading();
    const formVals: any = this.categoryForm.getRawValue();
    const parentCategoryIds = {};
    formVals.parentCategories.forEach((c: Category) => parentCategoryIds[c.id] = true);

    const newCategory: ICategory = {
      id: this.categoryId,
      name: formVals.name,
      imageUrl: formVals.imageUrl,
      gender: formVals.gender
    };
    const {parentCategories} = formVals;
    if (parentCategories && parentCategories.length > 0) newCategory.parentCategoryId = parentCategories[0].id;

    this.categoriesSrv.createUpdateCategory(newCategory, self.isEditing, formVals.subCategories.map(s => s.id)).pipe(
      map(() => {
        Globals.stopLoading();
        this.matSnackbar.open(`Category ${ this.isEditing ? 'edited' : 'created' }`, undefined, <MatSnackBarConfig>{
          duration: 2000
        });

        this.router.navigate(['categories']);
      }),
      take(1)
    ).subscribe();
  }

  selectParentCategories() {
    const self = this;
    self.categoryForm.markAsTouched();
    const idsToExclude = !!self.category && !!self.category.id ? [self.category.id] : undefined;
    const previouslySelectedIds = self.categoryForm.get('parentCategories').value.map(c => c.id);
    SelectCategoryComponent.open(this.dialog, true, idsToExclude, previouslySelectedIds, `Select parent categories`).pipe(
      map((parentCats: Category[]) => {
        if (!!parentCats) self.categoryForm.get('parentCategories').setValue(parentCats);
      }),
      take(1)
    ).subscribe();
  }

  selectSubcategories() {
    const self = this;
    self.categoryForm.markAsTouched();
    const idsToExclude = !!self.category && !!self.category.id ? [self.category.id] : undefined;
    const previouslySelectedIds =  self.categoryForm.get('subCategories').value.map(c => c.id);
    let selectedSubCats;
    SelectCategoryComponent.open(this.dialog, false, idsToExclude, previouslySelectedIds, `Select subcategories`).pipe(
      mergeMap((subCats: Category[]) => {
        selectedSubCats = subCats;
        const doSelectionsHaveParents = subCats.filter(s => !!s.parentCategoryId).length > 0;
        let nextStepObs = () => observableOf(true);
        if (doSelectionsHaveParents) nextStepObs = () => this.alertSrv.confirm(
          'Confirm Change',
          'The subcategories\' current parent categories will be overwritten. Do you want to continue?'
        );
        return nextStepObs();
      }),
      filter((willContinue: boolean) => willContinue),
      map(() => {
        if (!!selectedSubCats) self.categoryForm.get('subCategories').setValue(selectedSubCats);
      }),
      take(1)
    ).subscribe();
  }

  selectMutuallyExclusiveCategories() {
    const self = this;
    self.categoryForm.markAsTouched();
    const idsToExclude = !!self.category && !!self.category.id ? [self.category.id] : undefined;
    const previouslySelectedIds =  self.categoryForm.get('mutuallyExclusiveCategories').value.map(c => c.id);
    SelectCategoryComponent.open(this.dialog, false, idsToExclude, previouslySelectedIds, `Select mutually exclusive categories`).pipe(
      map((mutCats: Category[]) => {
        if (!!mutCats) self.categoryForm.get('mutuallyExclusiveCategories').setValue(mutCats);
      }),
      take(1)
    ).subscribe();
  }

  removeParentCategory(parent: ICategory) {
    const self = this;
    self.categoryForm.markAsTouched();
    const parentsControl = self.categoryForm.get('parentCategories');
    const newParents = parentsControl.value.filter((p: ICategory) => p.id !== parent.id);
    parentsControl.setValue(newParents);
  }


  removeSubCategory(sub: ICategory) {
    const self = this;
    self.categoryForm.markAsTouched();
    const parentsControl = self.categoryForm.get('subCategories');
    const newParents = parentsControl.value.filter((p: ICategory) => p.id !== sub.id);
    parentsControl.setValue(newParents);
  }

  removeMutualCategory(mut: ICategory) {
    const self = this;
    self.categoryForm.markAsTouched();
    const mutsControl = self.categoryForm.get('mutuallyExclusiveCategories');
    const newParents = mutsControl.value.filter((p: ICategory) => p.id !== mut.id);
    mutsControl.setValue(newParents);
  }

  deleteCategory(): void {
    this.alertSrv.confirm('Delete category?', 'Once deleted, you won\t be able to get it back.').pipe(
      filter((res: any) => !!res),
      mergeMap(() => {
        Globals.startLoading();
        return this.categoriesSrv.deleteCategory(this.categoryId);
      }),
      map(() => {
        Globals.stopLoading();
        this.matSnackbar.open(this.categoryForm.get('name').value + ' category deleted', 'Ok', <MatSnackBarConfig>{
          duration: 2000
        });

        this.router.navigate(['categories']);
      })
    ).subscribe();
  }

  updateImg(imgUrl) {
    this.categoryForm.markAsTouched();
    this.categoryForm.get('imageUrl').setValue(imgUrl);
  }

  clickedGenderCheckbox(willHave) {
    this.willHaveGender = willHave;
    if (!willHave) {
      this.categoryForm.get('gender').setValue(undefined);
    }
  }


  isChangedValidator(control: AbstractControl): ValidatorFn {

    return null;
  }

}
