
<div class="page-header">
  <span class="mat-display-2">
    FAQ
  </span>
</div>
<div class="full-width text-left">
  <div class="mat-headline">Add a new question</div>
</div>
<wz-sell-on-wedzee-form-item [isFaq]="true" (updated)="saveNewSection($event)"></wz-sell-on-wedzee-form-item>
<ng-container [ngSwitch]="!!isLoading">
  <div *ngSwitchCase="true" class="full-width text-center">
    <mat-spinner diameter="150" color="accent" style="margin: auto;"></mat-spinner>
  </div>
  <br><br><br><br>
  <ng-container *ngSwitchCase="false">
    <div *ngIf="data$ | async as d" class="full-width text-left">
      <div *ngIf="d?.length > 0" class="full-width text-left">
        <div class="mat-headline">Questions</div>
      </div>
      <wz-sell-on-wedzee-form-item
        *ngFor="let s of d; let i = index;"
        [value]="s"
        (moveUp)="moveUpInOrder(i)"
        (moveDown)="moveDownInOrder(i)"
        (delete)="deleteSection(i)"
        (updated)="updateSection(i, $event)"
        [isFaq]="true"
      ></wz-sell-on-wedzee-form-item>
    </div>
  </ng-container>
  <br><br><br><br>
</ng-container>