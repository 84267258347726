import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Globals, User } from '~shared/classes';
import { AlertService } from '~shared/services';

@Component({
  selector: 'wz-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {
  @Input() users: User[] = [];
  @Input() roleCapabilities: any = {};
  @Input() userRoles: string[] = [];
  @Output() updated: EventEmitter<void> = new EventEmitter();


  constructor(
    private alertSrv: AlertService,
    private http: HttpClient
  ) { }

  ngOnInit() {
  }


  getAvatarUrl(photoURL: string): string {
    return !!photoURL ? photoURL : 'https://firebasestorage.googleapis.com/v0/b/wedzee-dev.appspot.com/o/default_avatar.jpg?alt=media&token=019e3249-a31f-4d00-ba78-792c6828bda2';
  }

  copyToClipboard(copyText: string) {
    const textInput: HTMLInputElement = <HTMLInputElement>document.createElement('input');
    textInput.value = copyText;
    document.body.appendChild(textInput);
    textInput.select();
    document.execCommand('copy');
    this.alertSrv.successToast('Copied!');
    document.body.removeChild(textInput);
  }

  disableAccount(user: User) {
    this.alertSrv.confirm('Disable Account?', 'This user will be unable to log into the site.').pipe(
      filter((willDisable: boolean) => willDisable),
      mergeMap(() => user.disableAccount()),
      map(() => {
        this.alertSrv.successToast(`${user.displayName}'s account has been disabled`);
        this.updated.next();
      })
    ).subscribe();
  }

  enableAccount(user: User) {
    this.alertSrv.confirm('Enable Account?', 'This user will regain access to the site.').pipe(
      filter((willEnable: boolean) => willEnable),
      mergeMap(() => user.enableAccount()),
      map(() => {
        this.alertSrv.successToast(`${user.displayName}'s account has been enabled`);
        this.updated.next();
      })
    ).subscribe();
  }

  deleteAccount(user: User) {
    this.alertSrv.confirm('Delete Account?', 'This will delete all information about this user, including his/her items for sale.').pipe(
      filter((willEnable: boolean) => willEnable),
      mergeMap(() => {
        Globals.startLoading('Deleting user');
        return user.delete();
      }),
      map(() => {
        this.alertSrv.successToast(`Account has been deleted`);
        this.updated.next();
        Globals.stopLoading();
      })
    ).subscribe();
  }

  changeRole(user: User, role: string) {
    let confirmMsg = `Changing ${user.displayName}'s role to ${role} will give him/her the following capabilities:<br><ul>`;
    const capabilites: string[] = this.roleCapabilities[role];
    capabilites.forEach((c: string) => confirmMsg += `<li>${c}</li>`);
    confirmMsg += '</ul>';

    this.alertSrv.confirm('Change role?', confirmMsg
    ).pipe(
      filter((willChange: boolean) => willChange),
      mergeMap(() => user.changeRole(<any>role)),
      map(() => {
        this.alertSrv.successToast(`${user.displayName}'s role was changed to ${role}`);
        this.updated.next();
      })
    ).subscribe();
  }

  isCurrentUser(uid: string) {
    return Globals.user.id === uid;
  }

  canCurrentUserDelete(user: User) {
    const currentUser = Globals.user;
    return currentUser.id !== user.id && ((currentUser.role === 'admin' && user.role === 'consumer') || currentUser.role === 'superadmin');
  }

}
