<div class="text-left" *ngIf="listing$ | async as listing">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column">
      <a fxFlex class="mat-headline" target="_blank" [href]="consumerSiteUrl + '/' + listing | productUrl">
        <mat-icon>open_in_new</mat-icon>
        {{listing.name}}
      </a>
      <wz-tooltip matTooltip="Edit this listing from the consumer seller portal.">
        <a target="_blank" [href]="consumerSiteUrl + '/seller/edit-listing/' + listing.id" fxFlex="0 0 100px;" mat-flat-button color="accent">
          <mat-icon>create</mat-icon>
          Edit
        </a>
      </wz-tooltip>
      
      <wz-tooltip [matTooltip]="deleteTooltip(canDelete$ | async)">
        <button
          (click)="deleteListing()"
          [disabled]="!(canDelete$ | async)"
          fxFlex="0 0 125px;"
          mat-flat-button
          color="warn"
        >
          <mat-icon>delete</mat-icon> 
          Delete
        </button>
      </wz-tooltip>
  </div>
  
  <div class="form-hint">
    Created {{listing.createdTimestamp | date}}
  </div>
  <br>
  <div class="page-row" fxLayout.gt-sm="row" fxLayout.lt-md="column">
    <div fxFlex="1 1 48%;">
      <div class="mat-title">Images</div>
      <div class="img-container" *ngFor="let imgUrl of listing.images; let i = index;">
        <div>{{ i === 0 ? '*Primary' : i + 1}}</div>
        <img [src]="imgUrl" alt="">
      </div>

      <div class="mat-title">Orders</div>
      <ng-container *ngIf="orders$ | async as orders">
        <div *ngIf="orders.length === 0" class="not-found-text">This item hasn't been ordered yet</div>
        <wz-order-item *ngFor="let o of orders" [order]="o" (click)="openOrderDetail(o)"></wz-order-item>
      </ng-container>

    </div>
    <div fxFlex="1 1 48%;">
      <div class="mat-title">Price: ${{listing.price | number:'1.2-2'}}</div>

      <div class="mat-title">Quantity Available: {{listing.qtyAvailable}}</div>

      <div class="mat-title">Description</div>
      <a>
        {{listing.description | maxChars:currentDescChars }}
        <a
          style="display: block; margin: 10px; width: 100%; text-align: center; cursor: pointer;"
          class="form-hint" 
          (click)="currentDescChars = (descMaxChars === currentDescChars ? 9999999999 : descMaxChars)"
          *ngIf="listing.description.length > descMaxChars"
          [ngSwitch]="descMaxChars === currentDescChars"
        >
          <ng-container *ngSwitchCase="true">Show more</ng-container>
          <ng-container *ngSwitchCase="false">Show less</ng-container>
        </a>
      </a>

      <div class="mat-title">Categories</div>
      <ul>
        <li *ngFor="let categoryId of listing.categoryIds">{{categoriesLookup[categoryId]?.name}}</li>
      </ul>

      <div class="mat-title">Colors</div>
      <ul>
        <li *ngFor="let colorId of getColorIds(listing)">{{colorsLookup[colorId]?.name}}</li>
      </ul>

      <div class="mat-title">Seller Info</div>
      <div fxLayout="row" *ngIf="seller$ | async as s">
        <div fxFlex="0 0 100px" style="padding: 8px;">
          <img style="width: 100%;" [src]="s.photoURL" alt="Seller photo">
        </div>
        <div fxFlex class="seller-container">
          <div>@{{s.username}}</div>
          <div>{{s.firstName + ' ' + s.lastName}}</div>
          <div>{{s.email}}</div>
          <div>On wedzee since {{s.signUpDate | date}}</div>
          <br>
          <a target="_blank" [href]="consumerSiteUrl + '/profile/' + s.username"  mat-flat-button>
            <mat-icon>open_in_new</mat-icon>
            View Profile
          </a>
        </div>
      </div>
      
    </div>
  </div>
</div>
