<div class="mat-headline text-left">
  Exceptions
</div>

<mat-tab-group *ngIf="exceptions$ | async as exceptions">
  <mat-tab label="Client">
    <wz-exception *ngFor="let e of byEnv(exceptions, 'client')" [e]="e" (delete)="delete($event)"></wz-exception>
  </mat-tab>
  <mat-tab label="Server">
    <wz-exception *ngFor="let e of byEnv(exceptions, 'server')" [e]="e" (delete)="delete($event)"></wz-exception>
  </mat-tab>
</mat-tab-group>
