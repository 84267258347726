<div class="page-header">
    <span class="mat-display-2">
      Sell on Wedzee
    </span>
  </div>
<ng-container [ngSwitch]="!!isLoading">
    <div *ngSwitchCase="true" class="full-width text-center">
      <mat-spinner diameter="150" color="accent" style="margin: auto;"></mat-spinner>
    </div>
    <ng-container *ngSwitchCase="false">
      <div *ngIf="data$ | async as d" class="full-width text-left">
        <form *ngIf="tagLineForm as form" [formGroup]="form">
          <mat-form-field *ngIf="tagLineForm.get('tagLine') as ctrl" class="full-width">
            <mat-label>Tag line</mat-label>
            <input formControlName="tagLine" matInput placeholder="Tag line..." type="text">
            <mat-error *ngIf="!ctrl.valid">Tag line is required.</mat-error>
          </mat-form-field>
          <button mat-flat-button color="accent" [disabled]="!tagLineForm.get('tagLine').valid || !tagLineForm.touched" (click)="saveTagLine()">
            Save tag line
          </button>
        </form>
        <br><br>
        <div *ngIf="d?.sections?.length > 0" class="full-width text-left">
          <div class="mat-headline">Info sections</div>
        </div>
        <wz-sell-on-wedzee-form-item
          *ngFor="let s of d.sections; let i = index;"
          [value]="s"
          (moveUp)="moveUpInOrder(i)"
          (moveDown)="moveDownInOrder(i)"
          (delete)="deleteSection(i)"
          (updated)="updateSection(i, $event)"
        ></wz-sell-on-wedzee-form-item>
      </div>
    </ng-container>
    <br><br><br><br>
    <div class="full-width text-left">
      <div class="mat-headline">Add a new section</div>
    </div>
    <wz-sell-on-wedzee-form-item (updated)="saveNewSection($event)"></wz-sell-on-wedzee-form-item>
</ng-container>