<div class="page-header">
  <span class="mat-display-2">About</span>
</div>

<ng-container [ngSwitch]="!!isLoading">
  <div *ngSwitchCase="true" class="full-width text-center">
    <mat-spinner style="margin: auto!important;" color="accent" diameter="150"></mat-spinner>
  </div>
  <ng-container *ngSwitchCase="false">
      <wz-about-section-form-item
        *ngFor="let s of sections$ | async; let i = index"
        [section]="s"
        (updated)="updateSection(i, $event)"
        (delete)="deleteSection(i)"
        (moveUp)="moveUp(i)"
        (moveDown)="moveDown(i)"
        ></wz-about-section-form-item>
        
        <br><br><br>
        <div class="full-width text-left">
          <div class="mat-title">Add a new section</div>
        </div>
        
        <wz-about-section-form-item
          (updated)="saveNewSection($event)"
        ></wz-about-section-form-item>
  </ng-container>
</ng-container>