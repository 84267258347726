import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IHeadlineMessage, ISellOnWedzeeData } from 'wz-types';
import { Globals } from '~shared/classes';
import { AlertService, PagesPoliciesService, SeoService } from '~shared/services';


@Component({
  selector: 'wz-sell-on-wedzee-page',
  templateUrl: './sell-on-wedzee-page.component.html',
  styleUrls: ['./sell-on-wedzee-page.component.scss']
})
export class SellOnWedzeePageComponent implements OnInit {
  data$: Observable<ISellOnWedzeeData>;
  data: ISellOnWedzeeData;
  newData: ISellOnWedzeeData;
  isLoading: boolean;

  tagLineForm: FormGroup;

  constructor(
    private pagesPoliciesSrv: PagesPoliciesService,
    private alertSrv: AlertService,
    private formBuilder: FormBuilder,
    private seoSrv: SeoService
    ) {}

  ngOnInit() {
    Globals.userInstantiated$
      .pipe(
        take(1),
        map(() => {
          this.updateData();
        })
      ).subscribe();
  }

  updateData() {
    this.data$ = this.pagesPoliciesSrv.getSellOnWedzeeData(true).pipe(
      map((d: any) => {
        this.instantiateTaglineForm();
        this.data = d;
        if (!!d && !!d.tagLine) {
          this.tagLineForm.get('tagLine').setValue(d.tagLine);
        }
        return d;
      })
    );
  }

  instantiateTaglineForm() {
    this.tagLineForm = this.formBuilder.group({
      tagLine: [undefined, [Validators.required]]
    });
  }

  updateSection(index: number, data: IHeadlineMessage) {
    this.newData = this.data;
    this.newData.sections[index] = data;
    this.saveNewData();
  }

  saveTagLine() {
    this.newData = this.data;
    this.newData.tagLine = this.tagLineForm.get('tagLine').value;
    this.saveNewData();
  }

  saveNewData() {
    this.isLoading = true;
    this.pagesPoliciesSrv.saveSellOnWedzeeData(this.newData).pipe(
      map(() => {
        this.newData = undefined;
        this.updateData();
        this.alertSrv.successToast('Saved sell on Wedzee info!');
        this.isLoading = false;
      }),
      take(1)
    ).subscribe();
  }

  moveUpInOrder(initialIndex: number) {
    this.newData = this.data;
    const newSections = this.newData.sections.filter((s, i) => i !== initialIndex);
    const section = this.data.sections[initialIndex];
    const newIndex = initialIndex === 0 ? 0 : initialIndex - 1;
    newSections.splice(newIndex, 0, section);
    this.newData.sections = newSections;
    this.saveNewData();
  }

  moveDownInOrder(initialIndex: number) {
    this.newData = this.data;
    const newSections = this.newData.sections.filter((s, i) => i !== initialIndex);
    const section = this.data.sections[initialIndex];
    const newIndex = initialIndex === this.data.sections.length - 1 ? initialIndex : initialIndex + 1;
    newSections.splice(newIndex, 0, section);
    this.newData.sections = newSections;
    this.saveNewData();
  }

  saveNewSection(data: IHeadlineMessage) {
    this.newData = this.data;
    if (!this.newData) this.newData = <any>{ sections: [] };
    else if (!this.newData.sections) this.newData.sections = [];
    this.newData.sections.push(data);
    this.saveNewData();
  }

  deleteSection(index: number) {
    this.alertSrv.confirm('Delete section?', 'It will be deleted permanently').pipe(
      filter((didConfirm: boolean) => !!didConfirm),
      take(1),
      map(() => {
        this.newData = this.data;
        this.newData.sections = this.newData.sections.filter((s, i) => i !== index);
        this.saveNewData();
      })
    ).subscribe();
  }
}
