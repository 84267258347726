import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ICallToAction } from 'wz-types/home-page';
import { Globals } from '~shared/classes';
import { AlertService } from '~shared/services';

import { HomePageService } from '../../services/home-page/home-page.service';

@Component({
  selector: 'wz-call-to-action-page',
  templateUrl: './call-to-action-page.component.html',
  styleUrls: ['./call-to-action-page.component.scss']
})
export class CallToActionPageComponent implements OnInit {
  primaryCta: ICallToAction;
  secondaryCta: ICallToAction;
  originalBoxColor = '#EC407A';
  boxColor: string;
  colorForm: FormGroup;

  constructor(
    private homePageSrv: HomePageService,
    private alertSrv: AlertService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    const self = this;
    self.homePageSrv.getCallsToAction().pipe(
      map((ctas: ICallToAction[]) => {
        if (!!ctas) {
          this.primaryCta = ctas[0];
          this.secondaryCta = ctas[1];
          if (this.primaryCta.boxColor) {
            this.originalBoxColor = this.primaryCta.boxColor;
          }
          this.boxColor = this.originalBoxColor;
        }
      }),
      take(1),
      catchError((err) => {
        Globals.logError(err, 'ERROR GETTING CALLS TO ACTION', false);
        return observableOf(undefined);
      })
    ).subscribe();
  }


  updatePrimaryCta(cta: ICallToAction, isUpdate?: boolean) {
    this.homePageSrv.savePrimaryCallToAction(cta, isUpdate).pipe(
      map(() => this.alertSrv.successToast('Primary call to action updated!')),
      take(1)
    ).subscribe();
  }

  updateSecondaryCta(cta: ICallToAction, isUpdate?: boolean) {
    this.homePageSrv.saveSecondaryCallToAction(cta, isUpdate).pipe(
      map(() => this.alertSrv.successToast('Secondary call to action updated!')),
      take(1)
    ).subscribe();
  }

  updateBoxColor() {
    this.homePageSrv.updateBoxColor(this.boxColor).pipe(
      map(() => {
        this.ngOnInit();
        this.alertSrv.successToast('Box color updated.');
      }),
      take(1),
    ).subscribe();
  }

}
