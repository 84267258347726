import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IDisputeWithOrderAndListing } from 'wz-types/disputes';
import { ImgPreviewComponent } from '~shared/dialogs';
import { AlertService } from '~shared/services';
import { DisputesStore } from '~shared/stores';

@Component({
  selector: 'wz-dispute-item',
  templateUrl: './dispute-item.component.html',
  styleUrls: ['./dispute-item.component.scss']
})
export class DisputeItemComponent implements OnInit {
  @Input() dispute: IDisputeWithOrderAndListing;
  @Output() updated: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private disputesStore: DisputesStore,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
  }

  previewImage(url: string, index: number) {
    this.dialog.open(ImgPreviewComponent, {
      hasBackdrop: true,
      width: '500px',
      maxWidth: '90%',
      data: {
        imgUrl: url,
        imgName: `${this.dispute.id} image ${index + 1}`,
        imgType: 'disputeImage'
      }
    });
  }

  markAsResolved() {
    this.alertSrv.confirm('Resolve dispute?', '').pipe(
      filter(r => r),
      mergeMap(() => this.disputesStore.dispatch('markDisputeAsResolved', this.dispute.id)),
      map(() => {
        this.alertSrv.successToast('Dispute resolved!');
        this.updated.emit();
      })
    ).subscribe();
  }

  archive() {
    this.alertSrv.confirm('Archive dispute?', '').pipe(
      filter(r => r),
      mergeMap(() => this.disputesStore.dispatch('archiveDispute', this.dispute.id)),
      map(() => {
        this.alertSrv.successToast('Dispute archived!');
        this.updated.emit();
      })
    ).subscribe();
  }

}
