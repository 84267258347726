<div *ngIf="trustIconForms && trustIconForms.length === 0" class="full-width text-center">
  <mat-spinner style="width: 50%; margin-top: 150px;"></mat-spinner>
</div>

<ng-container *ngIf="!!iconNames && !!trustIconForms">
  <mat-card *ngFor="let f of trustIconForms; let i = index">
    <mat-card-header>
      <mat-card-title>
        <span class="mat-headline">{{ "Icon #" + (i + 1) }}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="full-width text-left">
        <div style="display: inline-block; width: 45%;  vertical-align: top;">

            <form [formGroup]="f">
              <mat-form-field class="full-width">
                <mat-label>Title</mat-label>
                <input
                  formControlName="title"
                  matInput
                  placeholder="Title"
                  type="text"
                />
                <mat-error *ngIf="!f.get('title').valid"
                  >Title is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>Message</mat-label>
                <textarea
                  formControlName="message"
                  matInput
                  placeholder="Message"
                  type="text"
                  style="min-height: 200px;"
                ></textarea>
                <mat-error *ngIf="!f.get('message').valid"
                  >Message is required!</mat-error
                >
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>Icon</mat-label>
                <input
                  matTooltip="Browse these icons at https://material.io/tools/icons"
                  type="text"
                  (keyup)="iconSearchFilterText$[i].next($event.target.value)"
                  placeholder="Icon"
                  matInput
                  formControlName="iconName"
                  [matAutocomplete]="iconNamesAutoComplete"
                />
              </mat-form-field>
              <mat-autocomplete #iconNamesAutoComplete>
                <mat-option
                  *ngFor="let n of iconNames | searchFilter: { searchText: (iconSearchFilterText$[i] | async) }"
                  [value]="n"
                >
                  <mat-icon>{{ n }}</mat-icon>
                  {{ displayIconName(n) }}
                </mat-option>
              </mat-autocomplete>
            </form>

        </div>
        <div style="display: inline-block; width: 40%; vertical-align: top; text-align: center; margin: 5px;">
          <wz-trust-icon [trustIcon]="f.getRawValue()"></wz-trust-icon>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <button mat-raised-button color="accent" class="full-width" [disabled]="!areAllFormsValid()" (click)="submitTrustIcons()" style="margin-top: 35px;">
    Save trust icons
  </button>
</ng-container>
