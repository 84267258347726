import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/firestore';
import { from as fromPromise, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ICategory, ICategoryWithSubcategories } from 'wz-types/categories.d';
import { Globals } from '~shared/classes';


export class Category implements ICategory {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  parentCategoryId: string;
  mutuallyExclusiveCategoryIds?: { [categoryId: string]: true };
  tags?: string[];
  isInMainMenu?: boolean;

  gender?: 'm' | 'f';

  subCategories: Category[];

  constructor(
    private firestore: AngularFirestore,
    private categoriesCollection: AngularFirestoreCollection,
    public categoryObj: ICategory
  ) {
    const self = this;
    Object.keys(categoryObj).forEach((catKey) => self[catKey] = categoryObj[catKey]);
    if (Globals.categoriesLookup) this.subCategories = <any>this.getSubcategoryTreeFromLookup();
  }

  getSubcategories(): Observable<Category[]> {
    return fromPromise(this.categoriesCollection.ref.where('parentCategoryId', '==', this.id).orderBy('gender', 'asc').get()).pipe(
      map((querySnap: QuerySnapshot<ICategory>) => querySnap.docs.map(d => this.instantiate(d.data())))
    );
  }

  updateSubcategories(newSubIds: string[]): Observable<void> {
    return this.getSubcategories().pipe(
      mergeMap((subCats: Category[]) => {
        const existingSubIds = subCats.map(s => s.id);
        const toAdd = newSubIds.filter(n => existingSubIds.indexOf(n) === -1);
        const toRemove = existingSubIds.filter(o => newSubIds.indexOf(o) === -1);
        const batch = this.firestore.firestore.batch();
        toAdd.forEach(a => batch.update(this.categoriesCollection.doc(a).ref, { parentCategoryId: this.id }));
        toRemove.forEach(r => batch.update(this.categoriesCollection.doc(r).ref, { parentCategoryId: null }));
        return fromPromise(batch.commit());
      })
    );
  }


  getSubcategoryTreeFromLookup(): ICategoryWithSubcategories[] {
    const getSubCats: (id: string) => any[] = (id: string) => Object.values(Globals.categoriesLookup).filter((c: ICategory) => c.parentCategoryId === id);
    const fillTree = (initialArray: ICategoryWithSubcategories[], treeAncestorIds: string[]) => {
      let thisArray = [];
      initialArray.forEach((c: ICategoryWithSubcategories, index: number) => {
        const subCats = getSubCats(c.id);
        c.subCategories = treeAncestorIds.indexOf(c.id) > -1 ? [] : fillTree(subCats, [...treeAncestorIds, c.id]);
        if (true) thisArray.push(c);
      });

      thisArray = thisArray.sort((a, b) => {
        const aNumOfSubcats = !a.subCategories ? 0 : a.subCategories.length;
        const bNumOfSubCats = !b.subCategories ? 0 : b.subCategories.length;
        return bNumOfSubCats - aNumOfSubcats;
      });

      return thisArray;
    };

    return fillTree(getSubCats(this.id), [this.id]);
  }

  isInSubCategoryTree(subCatId: string) {
    const tree = this.subCategories || this.getSubcategoryTreeFromLookup();
    let isIn = false;
    const checkIfInSubCats = (subCats: ICategory[]) => {
     isIn = subCats.map((sub: ICategory) => sub.id).indexOf(subCatId) > -1;
      if (!isIn) subCats.forEach((c: ICategoryWithSubcategories) => checkIfInSubCats(c.subCategories));
      return isIn;
    };
    return checkIfInSubCats(tree);
  }


  private instantiate(catDoc: ICategory): Category {
    return new Category(this.firestore, this.categoriesCollection, catDoc);
  }
}
