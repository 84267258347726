import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ICallToAction } from 'wz-types/home-page';
import { Globals } from '~shared/classes';
import { SelectCategoryComponent } from '~shared/dialogs';
import { AlertService, PagesPoliciesService } from '~shared/services';

import { Category } from '../../classes/category.class';
import { CategoriesService } from '../../services/categories/categories.service';
import { HomePageService } from '../../services/home-page/home-page.service';

@Component({
  selector: 'wz-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  destroy$: Subject<void> = new Subject();
  mainMenuCategories: Category[];
  primaryCta: ICallToAction;
  secondaryCta: ICallToAction;
  bannerMessage$: Observable<string>;
  bannerMessageForm: FormGroup;

  constructor(
    private categoriesSrv: CategoriesService,
    private homePageSrv: HomePageService,
    private pagesPoliciesSrv: PagesPoliciesService,
    private formBuilder: FormBuilder,
    private alertSrv: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const self = this;
    this.refreshBannerMessage();
    self.updateMainMenuCategories().pipe(take(1)).subscribe();
    self.homePageSrv.getCallsToAction().pipe(
      map((ctas: ICallToAction[]) => {
        if (!!ctas) {
          this.primaryCta = ctas[0];
          this.secondaryCta = ctas[1];
        }
      }),
      take(1),
      catchError((err) => {
        Globals.logError(err, 'ERROR GETTING CALLS TO ACTION: ', false);
        return observableOf(undefined);
      })
    ).subscribe();
  }

  refreshBannerMessage() {
    this.bannerMessage$ = this.pagesPoliciesSrv.getBannerMessage().pipe(
      map((msg: string) => {
        this.bannerMessageForm = this.formBuilder.group({
          bannerMessage: [msg]
        });
        return msg;
      }),
      take(1)
    );
  }

  updateBannerMessage() {
    this.pagesPoliciesSrv.saveBannerMessage(this.bannerMessageForm.get('bannerMessage').value).pipe(
      map(() => {
        this.refreshBannerMessage();
      }),
      take(1)
    ).subscribe();
  }

  selectMainMenuCategories(): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: { previouslySelectedIds: this.mainMenuCategories.map(cat => cat.id) }
    });

    dialogRef.afterClosed().pipe(
      mergeMap((categories: Category[]) => {
        const catIds = categories.map(c => c.id);
        return this.categoriesSrv.setMainMenuCategories(catIds);
      }),
      mergeMap(() => this.updateMainMenuCategories()),
      take(1)
    ).subscribe();
  }

  updateMainMenuCategories(): Observable<void> {
    const self = this;
    return this.categoriesSrv.getMainMenuCategories().pipe(
      map((mainMenCats: Category[]) => {
        self.mainMenuCategories = mainMenCats;
      }),
      take(1)
    );
  }

  updatePrimaryCta(cta: ICallToAction, isUpdate?: boolean) {
    this.homePageSrv.savePrimaryCallToAction(cta, isUpdate).pipe(
      map(() => this.alertSrv.successToast('Primary call to action updated!')),
      take(1)
    ).subscribe();
  }

  updateSecondaryCta(cta: ICallToAction, isUpdate?: boolean) {
    this.homePageSrv.saveSecondaryCallToAction(cta, isUpdate).pipe(
      map(() => this.alertSrv.successToast('Secondary call to action updated!')),
      take(1)
    ).subscribe();
  }

}
