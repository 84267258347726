import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IListing } from 'wz-types/listings';

@Component({
  selector: 'wz-set-featured-listings',
  templateUrl: './set-featured-listings.component.html',
  styleUrls: ['./set-featured-listings.component.scss']
})
export class SetFeaturedListingsComponent implements OnInit {
  featuredListings$: Observable<IListing>;
  featuredIds: string[] = [];
  docId = 'featuredListingIds';

  constructor(
    private firebaseDb: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.featuredListings$ = this.firebaseDb.collection('homePage').doc(this.docId).valueChanges().pipe(
      filter((d: any) => !!d && d.featuredListingIds),
      switchMap((data: { featuredListingIds: string[]; id: 'featuredListingIds' }) => {
        this.featuredIds = data.featuredListingIds;
        const getListingById = (listingId: string) => this.firebaseDb.collection('listings').doc(listingId).get().pipe(map(doc => doc.data()));
        // tslint:disable-next-line: deprecation
        return forkJoin(...data.featuredListingIds.map(id => getListingById(id)));
      })
    );
  }

  remove(index: number) {
    this.featuredIds = this.featuredIds.filter((v, i) => i !== index);
    this.updateFeaturedListingIds(this.featuredIds);
  }

  move(index: number, difference: number) {
    const desiredIndex = index + difference;
    const canMove = desiredIndex <= this.featuredIds.length && desiredIndex >= 0;
    if (canMove) {
      const thisId = this.featuredIds[index];
      const replaceId = this.featuredIds[desiredIndex];
      const newIds = this.featuredIds;
      newIds[desiredIndex] = thisId;
      newIds[index] = replaceId;
      this.updateFeaturedListingIds(newIds);
    }
  }

  async addListing() {
    const id = prompt('Please enter listing id: ').trim();
    console.log('*********', id);
    if (id && !this.featuredIds.includes(id)) {
      console.log('IN HERE', id);
      this.featuredIds.push(id);
      this.updateFeaturedListingIds(this.featuredIds);
    }
  }

  updateFeaturedListingIds(ids: string[]) {
    this.firebaseDb.collection('homePage').doc(this.docId).set({ id: this.docId, [this.docId]: ids });
  }

}
