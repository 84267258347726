<mat-card style="text-align: left; margin: 18px;">
  <h1 class="mat-headline">Featured Listings</h1>
  <div style="display: inline-block;" *ngFor="let listing of featuredListings$ | async; let i = index;">
    <div>
      <button (click)="remove(i)" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
      <button (click)="move(i, -1)" mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button (click)="move(i, 1)" mat-icon-button>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <wz-product [doc]="listing"></wz-product>
  </div>

  <mat-card-actions>
    <button style="width: 100%;" mat-raised-button color="accent" (click)="addListing()">
      Add featured listing
    </button>
  </mat-card-actions>

</mat-card>
