<div style="text-align: left;">
  <h2 class="mat-headline">Site events</h2>
  <table>
    <thead>
      <tr>
        <th style="min-width: 125px;">Time</th>
        <th>Data</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let event of events">
        <td>
          {{event.timestamp | date:'short'}}
        </td>
        <td>
          {{event.info}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
