<mat-card *ngIf="form">
  <mat-card-content>
    <form [formGroup]="form">
      <mat-form-field *ngIf="form.get('headline') as ctrl" class="full-width">
        <mat-label>{{ !!isFaq ? 'Question' : 'Headline'}}</mat-label>
        <input formControlName="headline" matInput [placeholder]="(isFaq ? 'Question' : 'Headline') + '...'" type="text">
        <mat-error *ngIf="!ctrl.valid">{{ isFaq ? 'Question' : 'Headline' }} is required.</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="form.get('message') as ctrl" class="full-width">
        <mat-label>{{ !!isFaq ? 'Answer' : 'Message'}}</mat-label>
        <textarea [style.height]="!!isFaq ? '110px' : 'auto'" formControlName="message" matInput [placeholder]="(!!isFaq ? 'Answer' : 'Message') + '...'" type="text"></textarea>
        <mat-error *ngIf="!ctrl.valid">{{!!isFaq ? 'Answer' : 'Message'}} is required.</mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions [ngSwitch]="!!value">
    <button (click)="save()" [disabled]="!form.valid || !form.touched" mat-flat-button color="accent" class="update-btn">
      <span *ngSwitchCase="true">Update</span>
      <span *ngSwitchCase="false">Save</span>
    </button>
    <ng-container *ngSwitchCase="true" class="other-btns">
      <button mat-icon-button matTooltip="Move up in order" (click)="moveUp.emit()">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Move down in order" (click)="moveDown.emit()">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button mat-icon-button color="warn" matTooltip="Delete" (click)="delete.emit()">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>
  </mat-card-actions>
</mat-card>
