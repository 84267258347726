import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IAboutPageSection } from 'wz-types';
import { Globals } from '~shared/classes';
import { AlertService, PagesPoliciesService } from '~shared/services';



@Component({
  selector: 'wz-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
  sections$: Observable<IAboutPageSection[]>;
  sections: IAboutPageSection[];
  newSections: IAboutPageSection[];
  isLoading: boolean;

  constructor(
    private pagesPoliciesSrv: PagesPoliciesService,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    Globals.userInstantiated$.pipe(
      take(1),
      map(() => {
        this.updateSections();
      })
    ).subscribe();
  }

  updateSections() {
    this.sections$ = this.pagesPoliciesSrv.getAboutSections(true).pipe(map((s: IAboutPageSection[]) => {
      this.sections = s;
      return s;
    }));
  }

  saveSections() {
    this.isLoading = true;
    this.pagesPoliciesSrv.saveAboutSections(this.newSections).pipe(
      take(1),
      map(() => {
        this.updateSections();
        this.newSections = undefined;
        this.alertSrv.successToast('About sections updated!');
        this.isLoading = false;
      })
    ).subscribe();
  }

  saveNewSection(s: IAboutPageSection) {
    this.newSections = this.sections || [];
    this.newSections.push(s);
    this.saveSections();
  }

  updateSection(index: number, newData: IAboutPageSection) {
    this.newSections = this.sections;
    this.newSections[index] = newData;
    this.saveSections();
  }

  deleteSection(index: number) {
    this.alertSrv.confirm('Delete section?', 'This data will be permanently removed.').pipe(
      take(1),
      filter((didConfirm: boolean) => !!didConfirm),
      map(() => {
        this.newSections = this.sections.filter((s, i) => i !== index);
        this.saveSections();
      })
    ).subscribe();
  }

  moveUp(initialIndex: number) {
    this.newSections = this.sections.filter((s, i) => i !== initialIndex);
    const section = this.sections[initialIndex];
    const newIndex = initialIndex === 0 ? 0 : initialIndex - 1;
    this.newSections.splice(newIndex, 0, section);
    this.saveSections();
  }

  moveDown(initialIndex: number) {
    this.newSections = this.sections.filter((s, i) => i !== initialIndex);
    const section = this.sections[initialIndex];
    const newIndex = initialIndex === this.sections.length  - 1 ? initialIndex : initialIndex + 1;
    this.newSections.splice(newIndex, 0, section);
    this.saveSections();
  }

}
