<div style="text-align: left;">
  <h2 class="mat-headling">Reset Database</h2>
  <div style="width: 250px;">
    <mat-label>Backup File</mat-label>
    <mat-select (selectionChange)="selectedOption = $event.value">
      <mat-option *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-select>
  </div>
  <br><br>
  <button (click)="onSubmit()" mat-flat-button color="primary" [disabled]="!selectedOption || isResetting">
    Reset Database
  </button>
</div>

<mat-spinner *ngIf="isResetting" diameter="150"></mat-spinner>

<h3 *ngIf="isDone">
  Database successfully reset!
</h3>

