import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IWedzeeException } from 'wz-types/wedzee';
import { AlertService } from '~shared/services';

@Component({
  selector: 'wz-exceptions-page',
  templateUrl: './exceptions-page.component.html',
  styleUrls: ['./exceptions-page.component.scss']
})
export class ExceptionsPageComponent implements OnInit {

  exceptions$: Observable<IWedzeeException[]>;

  constructor(
    private firestore: AngularFirestore,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    this.exceptions$ = from(this.firestore.collection('exceptions').ref.orderBy('timestamp', 'desc').limit(100).get()).pipe(
      map((qSnap) => qSnap.docs.map(d => <IWedzeeException>d.data()))
    );
  }

  async delete(id: string) {
    const willDelete = await this.alertSrv.confirm('Delete error?', '').toPromise();
    if (willDelete) {
      await this.firestore.collection('exceptions').doc(id).delete();
      this.ngOnInit();
    }
  }

  byEnv(exceptions: IWedzeeException[], env: string) {
    return exceptions.filter(e => e.environment === env);
  }

}
