import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IHeadlineMessage, ISellOnWedzeeData } from 'wz-types/page-data';
import { Globals } from '~shared/classes';
import { AlertService, PagesPoliciesService } from '~shared/services';


@Component({
  selector: 'wz-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  data$: Observable<ISellOnWedzeeData>;
  data: IHeadlineMessage[] = [];
  newData: IHeadlineMessage[] = [];
  isLoading: boolean;

  constructor(
    private pagesPoliciesSrv: PagesPoliciesService,
    private alertSrv: AlertService,
    private formBuilder: FormBuilder
    ) {}

  ngOnInit() {
    Globals.userInstantiated$
      .pipe(
        take(1),
        map(() => {
          this.updateData();
        })
      ).subscribe();
  }

  updateData() {
    this.data$ = this.pagesPoliciesSrv.getFaqData(true).pipe(
      map((d: any) => {
        this.data = d || [];
        return d;
      })
    );
  }

  updateSection(index: number, data: IHeadlineMessage) {
    this.newData = this.data;
    this.newData[index] = data;
    this.saveNewData();
  }

  saveNewData() {
    this.isLoading = true;
    this.pagesPoliciesSrv.saveFaqData(this.newData).pipe(
      map(() => {
        this.newData = [];
        this.updateData();
        this.alertSrv.successToast('Saved sell on Wedzee info!');
        this.isLoading = false;
      }),
      take(1)
    ).subscribe();
  }

  moveUpInOrder(initialIndex: number) {
    this.newData = this.data;
    const newSections = this.newData.filter((s, i) => i !== initialIndex);
    const section = this.data[initialIndex];
    const newIndex = initialIndex === 0 ? 0 : initialIndex - 1;
    newSections.splice(newIndex, 0, section);
    this.newData = newSections;
    this.saveNewData();
  }

  moveDownInOrder(initialIndex: number) {
    this.newData = this.data;
    const newSections = this.newData.filter((s, i) => i !== initialIndex);
    const section = this.data[initialIndex];
    const newIndex = initialIndex === this.data.length - 1 ? initialIndex : initialIndex + 1;
    newSections.splice(newIndex, 0, section);
    this.newData = newSections;
    this.saveNewData();
  }

  saveNewSection(data: IHeadlineMessage) {
    this.newData = this.data;
    if (!this.newData) this.newData = <any>{ sections: [] };
    else if (!this.newData) this.newData = [];
    this.newData.push(data);
    this.saveNewData();
  }

  deleteSection(index: number) {
    this.alertSrv.confirm('Delete question?', 'It will be deleted permanently').pipe(
      filter((didConfirm: boolean) => !!didConfirm),
      take(1),
      map(() => {
        this.newData = this.data;
        this.newData = this.newData.filter((s, i) => i !== index);
        this.saveNewData();
      })
    ).subscribe();
  }

}
