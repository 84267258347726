<table mat-table class="user-table" [dataSource]="users">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let user">
        <button style="margin: auto;" matTooltip="Copy ID to clipboard" (click)="copyToClipboard(user.id)" mat-icon-button color="accent">
          <mat-icon color="accent">file_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="photo">
      <th mat-header-cell *matHeaderCellDef>Photo</th>
      <td mat-cell *matCellDef="let user">
        <img style="height: 35px; border-radius: 100%;" [src]="getAvatarUrl(user.photoURL)" alt="">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user"> {{user.displayName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user"> {{user.email}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let user">
        <button [disabled]="isCurrentUser(user.id)" matTooltip="Click to change user role" mat-button [matMenuTriggerFor]="changeRoleMenu">
          {{user.role}}
        </button>
        <mat-menu #changeRoleMenu>
          <ng-container *ngFor="let roleOption of userRoles">
            <button *ngIf="roleOption !== user.role" mat-menu-item (click)="changeRole(user, roleOption)">
              {{roleOption}}
            </button>
          </ng-container>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="signUpDate">
      <th mat-header-cell *matHeaderCellDef>Created Account</th>
      <td mat-cell *matCellDef="let user"> {{user.signUpDate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="lastLoginDate">
      <th mat-header-cell *matHeaderCellDef>Last Signed In</th>
      <td mat-cell *matCellDef="let user"> {{user.lastLoginDate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="disable">
      <th mat-header-cell *matHeaderCellDef>Account Disabled</th>
      <td mat-cell *matCellDef="let user" [ngSwitch]="!!user.isDisabled">
        <button [disabled]="isCurrentUser(user.id)" mat-button *ngSwitchCase="false" style="margin: auto;" matTooltip="Click to disable this account" (click)="disableAccount(user)">
          Disable
        </button>
        <button [disabled]="isCurrentUser(user.id)" *ngSwitchCase="true" style="margin: auto;" matTooltip="Enable this account" (click)="enableAccount(user)" mat-icon-button color="accent">
          <mat-icon>check_circle</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button [disabled]="!canCurrentUserDelete(user)" mat-button style="margin: auto;" matTooltip="Delete this account" (click)="deleteAccount(user)">
          Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['id', 'photo', 'name', 'email', 'role', 'signUpDate', 'lastLoginDate', 'disable', 'delete']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['id', 'photo', 'name', 'email', 'role', 'signUpDate', 'lastLoginDate', 'disable', 'delete'];"></tr>
</table>
