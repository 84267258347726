<div class="page-header">
  <span class="mat-display-2">Categories</span>
  <button mat-raised-button [routerLink]="['create']" color="accent">
    <mat-icon>add</mat-icon>
    Add Category
  </button>
</div>

<div class="full-width">
  <mat-form-field appearance="outline" class="full-width">
    <input type="search" matInput placeholder="Filter categories" (keyup)="searchText$.next($event.target.value)">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<mat-progress-bar mode="buffer" *ngIf="isLoading"></mat-progress-bar>

<h3 class="mat-headline" style="margin-top: 10vh;" *ngIf="!isLoading && allCategories.length < 1">There are no categories</h3>

<mat-card style="text-align: left !important;" *ngFor="let cat of allCategories | searchFilter: { fields: ['name', 'description'], searchText: (searchText$ | async) }">
  <mat-card-title>
    <img matCardAvatar [src]="cat.imageUrl" alt="">
    <span style="margin: -15px auto auto 15px!important;" class="mat-display-1">{{cat.name}}</span>
  </mat-card-title>

  <mat-card-subtitle>
    {{cat.description}}
  </mat-card-subtitle>

  <mat-card-content *ngIf="!!cat.subCategories && cat.subCategories.length > 0">
    <mat-expansion-panel expanded="false" class="cat-expansion">
        <mat-expansion-panel-header>
          Subcategories ({{cat.subCategories.length}})
        </mat-expansion-panel-header>
        <mat-list>
          <a mat-list-item *ngFor="let c of cat.subCategories" [routerLink]="['edit', c.id]">
            <img matListAvatar [src]="c.imageUrl" alt="">
            <h3 matLine>{{c.name}}</h3>
          </a>
        </mat-list>
      </mat-expansion-panel>
  </mat-card-content>
  <mat-card-actions>
    <button color="accent" mat-button [routerLink]="['edit', cat.id]">View Details</button>
    <button color="accent" mat-button (click)="editSubcategories(cat)">{{!!cat.subCategories && cat.subCategories.length > 0 ? 'Change' : 'Add'}} Subcategories</button>
    <button style="float: right!important;" color="warn" mat-button (click)="deleteCategory(cat.id)">Delete</button>
  </mat-card-actions>
</mat-card>

<mat-paginator *ngIf="!!allCategories && allCategories.length > 1" [length]="allCategories.length"
              [pageSize]="numPerPage"
              [pageSizeOptions]="[10, 20, 50]"
              (page)="paginatorEvent$.next($event)">
</mat-paginator>
