<form [formGroup]="textForm">
  <quill-editor #editor
  [modules]="quillModules"
  [style.font-size]="'large'"
  [style.display]="'block'"
  [style.height]="'700px'"
  [style.width]="'80%'"
  [style.max-width]="'1000px'"
  [style.margin]="'auto'"
  [style.background-color]="'white'"
  [placeholder]="placeholder"
  (onSelectionChanged)="selectionChanged.next($event)"
  formControlName="value">
  </quill-editor>
</form>
