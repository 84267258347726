import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ITrustIcon } from 'wz-types/home-page';
import { AlertService } from '~shared/services';

import { HomePageService } from '../../services/home-page/home-page.service';
import { iconNames } from './icon-list';

@Component({
  selector: 'wz-trust-icon-form',
  templateUrl: './trust-icon-form.component.html',
  styleUrls: ['./trust-icon-form.component.scss']
})
export class TrustIconFormComponent implements OnInit, OnChanges {
  @Input() originalData: ITrustIcon[];
  isLoading = true;
  trustIconForms: FormGroup[] = [];
  iconNames = iconNames;
  iconSearchFilterText$: BehaviorSubject<string>[] = [new BehaviorSubject(''), new BehaviorSubject(''), new BehaviorSubject('')];

  constructor(
    private formBuilder: FormBuilder,
    private homePageSrv: HomePageService,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    this.iconNames = this.iconNames.sort();
    this.homePageSrv.getTrustIcons().pipe(
      map((icons: ITrustIcon[] | undefined) => {
        icons = icons || [undefined, undefined, undefined];
        this.trustIconForms = icons.map(i => this.getSingleTrustIconForm(i));
      })
    ).subscribe();
  }

  ngOnChanges() {
    this.trustIconForms = this.originalData.map(u => this.getSingleTrustIconForm());
  }

  submitTrustIcons() {
    return this.homePageSrv.saveTrustIcons(this.trustIconForms.map(f => f.getRawValue())).pipe(
      take(1),
      map(() => this.alertSrv.successToast(`Trust icons updated!`))
    ).subscribe();
  }

  getSingleTrustIconForm(data?: ITrustIcon) {
    const getProp = (propName: string) => !!data && !!data[propName] ? data[propName] : undefined;
    return this.formBuilder.group({
      title: [getProp('title'), [Validators.required]],
      message: [getProp('message'), [Validators.required]],
      iconName: [getProp('iconName'), [Validators.required]]
    });
  }

  areAllFormsValid() {
    return !!this.trustIconForms && this.trustIconForms.length > 0 &&
      this.trustIconForms.filter(f => f.valid).length === this.trustIconForms.length &&
      this.trustIconForms.filter(f => f.touched).length > 0;
  }

  displayIconName(iconName: string) {
    return iconName.split('_').join(' ');
  }

}
