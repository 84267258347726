import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IWeddingColor } from 'wz-types/home-page';
import { FirestoreRefs } from '~shared/classes';
import { wzCatchObservableError } from '~shared/services';

import { HomePageService } from '../home-page/home-page.service';

@Injectable({
  providedIn: 'root'
})
export class WeddingColorsService {
  public static colorsLookup: { [id: string]: IWeddingColor };
  private fileName = 'wedding-color.service.ts';

  constructor(
    private firestore: AngularFirestore,
    private homePageSrv: HomePageService
  ) {
  }

  getWeddingColors(): Observable<IWeddingColor[]> {
    return from(FirestoreRefs.weddingColors.get()).pipe(
      map((querySnap: firebase.firestore.QuerySnapshot) => {
        const allColors = <IWeddingColor[]>querySnap.docs.map(snap => snap.data());
        if (!WeddingColorsService.colorsLookup) {
          const newLookup = {};
          allColors.forEach((c: IWeddingColor) => newLookup[c.id] = c);
          WeddingColorsService.colorsLookup = newLookup;
        }
        return allColors;
      }),
      wzCatchObservableError(this.fileName, 'getWeddingColors()')
    );
  }

  createWeddingColor(color: IWeddingColor) {
    return from(FirestoreRefs.weddingColors.doc(color.id).set(color)).pipe(
      mergeMap(() => this.homePageSrv.updateHomePageCache(true)),
      wzCatchObservableError(this.fileName, 'createWeddingColor()')
    );
  }

  updateWeddingColor(color: IWeddingColor) {
    return from(FirestoreRefs.weddingColors.doc(color.id).update(color)).pipe(
      mergeMap(() => this.homePageSrv.updateHomePageCache(true)),
      wzCatchObservableError(this.fileName, 'updateWeddingColor()')
    );
  }

  getWeddingColorById(weddingColorId: string): Observable<IWeddingColor> {
    let res: () => Observable<IWeddingColor>;
    if (this.isInLookup(weddingColorId)) res = () => of(WeddingColorsService.colorsLookup[weddingColorId]);
    else res = () => from(FirestoreRefs.weddingColors.doc(weddingColorId).get()).pipe(
      map((snapshot: any) => snapshot.data()),
      wzCatchObservableError(this.fileName, 'getWeddingColorById()')
    );
    return res();
  }

  private isInLookup(id: string) {
    return !!WeddingColorsService.colorsLookup && !!WeddingColorsService.colorsLookup[id];
  }
}
