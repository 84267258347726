<div class="page-header">
  <span class="mat-display-2">Wedding Colors</span>
  <button mat-raised-button (click)="addColor()" color="accent">
    <mat-icon>add</mat-icon>
    Add Color
  </button>
</div>
<div class="full-width text-left">
  <wz-wedding-color *ngFor="let c of weddingColors$ | async" [doc]="c" (click)="editColor(c)"></wz-wedding-color>

</div>
