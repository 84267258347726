import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { CreateEditItemPageComponent } from '~shared/pages/create-edit-item-page/create-edit-item-page.component';

import { AboutPageComponent } from './pages/about-page/about-page.component';
import { CallToActionPageComponent } from './pages/call-to-action-page/call-to-action-page.component';
import { CategoriesPageComponent } from './pages/categories/categories-page/categories-page.component';
import {
  CreateEditCategoryPageComponent
} from './pages/categories/create-edit-category-page/create-edit-category-page.component';
import { DisputesPageComponent } from './pages/disputes-page/disputes-page.component';
import { ExceptionsPageComponent } from './pages/exceptions-page/exceptions-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ImpersonateComponent } from './pages/impersonate/impersonate.component';
import { ItemsPageComponent } from './pages/items-page/items-page.component';
import { ListingDetailPageComponent } from './pages/items-page/listing-detail-page/listing-detail-page.component';
import { MainMenuCategoriesPageComponent } from './pages/main-menu-categories-page/main-menu-categories-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { PeoplePageComponent } from './pages/people-page/people-page.component';
import { PrivacyPolicyEditorPageComponent } from './pages/privacy-policy-editor-page/privacy-policy-editor-page.component';
import { ResetDatabasePageComponent } from './pages/reset-database-page/reset-database-page.component';
import { SellOnWedzeePageComponent } from './pages/sell-on-wedzee-page/sell-on-wedzee-page.component';
import { SellerAgreementPageComponent } from './pages/seller-agreement-page/seller-agreement-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { SiteEventsComponent } from './pages/site-events/site-events.component';
import { TermsOfServicePageEditorComponent } from './pages/terms-of-service-page-editor/terms-of-service-page.component';
import { TestShipmentTrackingComponent } from './pages/test-shipment-tracking/test-shipment-tracking.component';
import { TrustIconsPageComponent } from './pages/trust-icons-page/trust-icons-page.component';
import { WeddingColorsPageComponent } from './pages/wedding-colors-page/wedding-colors-page.component';


const routes: Routes = [
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'about',
    component: AboutPageComponent
  },
  {
    path: 'categories',
    component: CategoriesPageComponent
  },
  {
    path: 'exceptions',
    component: ExceptionsPageComponent
  },
  {
    path: 'orders',
    component: OrdersPageComponent
  },
  {
    path: 'categories/create',
    component: CreateEditCategoryPageComponent
  },
  {
    path: 'categories/edit/:categoryId',
    component: CreateEditCategoryPageComponent
  },
  {
    path: 'listings',
    component: ItemsPageComponent
  },
  {
    path: 'listings/:listingId',
    component: ListingDetailPageComponent
  },
  {
    path: 'items/create',
    component: CreateEditItemPageComponent
  },
  {
    path: 'items/edit/:itemId',
    component: CreateEditItemPageComponent
  },
  {
    path: 'people',
    component: PeoplePageComponent
  },
  {
    path: 'disputes',
    component: DisputesPageComponent
  },
  {
    path: 'terms-of-service',
    component: TermsOfServicePageEditorComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyEditorPageComponent
  },
  {
    path: 'home/main-menu-categories',
    component: MainMenuCategoriesPageComponent
  },
  {
    path: 'home/call-to-action',
    component: CallToActionPageComponent
  },
  {
    path: 'home/trust-icons',
    component: TrustIconsPageComponent
  },
  {
    path: 'wedding-colors',
    component: WeddingColorsPageComponent
  },
  {
    path: 'settings',
    component: SettingsPageComponent
  },
  {
    path: 'sell-on-wedzee',
    component: SellOnWedzeePageComponent
  },
  {
    path: 'site-events',
    component: SiteEventsComponent
  },
  {
    path: 'faq',
    component: FaqPageComponent
  },
  {
    path: 'seller-agreement',
    component: SellerAgreementPageComponent
  },
  {
    path: 'impersonate',
    component: ImpersonateComponent
  },
  {
    path: 'test-shipment-tracking',
    component: TestShipmentTrackingComponent
  },
  {
    path: 'reset-database',
    component: ResetDatabasePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    QuillModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { }
