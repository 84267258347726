<form *ngIf="!!form" [formGroup]="form">
  <mat-card>
    <mat-card-content>
      <mat-form-field *ngIf="getCtrl('headline') as ctrl" class="full-width">
        <mat-label>Headline</mat-label>
        <input formControlName="headline" matInput placeholder="Headline..." type="text">
        <mat-error *ngIf="!ctrl.valid">Headline is required. It must be between 2 and 100 characters</mat-error>
      </mat-form-field>

      <div class="full-width text-left split-container">
        <div>
          <div *ngIf="getCtrl('headlinePosition') as ctrl" class="full-width text-left">
            <div clas="mat-subheading-2">Headline position</div>
            <br>
            <mat-radio-group color="accent" (change)="ctrl.setValue($event.value); form.markAsTouched()">
              <mat-radio-button style="display: block;" [checked]="ctrl.value === 'left'"  value="left">
                Left
              </mat-radio-button>
              <mat-radio-button [checked]="ctrl.value === 'right'" value="right">
                Right
              </mat-radio-button>
            </mat-radio-group>
          </div>
    
          <br><br>
    
          <mat-form-field class="full-width" *ngIf="getCtrl('longText') as ctrl">
            <mat-label>Message</mat-label>
            <textarea
              formControlName="longText"
              matInput
              placeholder="Message"
              type="text"
              style="min-height: 150px;"
            ></textarea>
            <mat-error *ngIf="!ctrl.valid"
              >Message is required. It must be between 2 and 275 characters</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <div clas="mat-subheading-2">Background image</div>
          <wz-add-image *ngIf="getCtrl('backgroundImgUrl') as ctrl"
            imgType="aboutSectionItem"
            [aspectRatio]="{ width: 2.25, height: 1 }"
            [originalImgUrl]="ctrl.value"
            (imgAdded)="ctrl.setValue($event)">
          </wz-add-image>
        </div>
      </div>


    </mat-card-content>
    <mat-card-actions class="text-left" [ngSwitch]="!!section">
      <button 
        style="width: calc(100% - 155px);"
        mat-flat-button 
        (click)="saveUpdated()"
        color="accent"
        [disabled]="!form?.valid || !form?.touched"
      >
        <span *ngSwitchCase="true">Save changes</span>
        <span *ngSwitchCase="false">Save section</span>
      </button>
      <button (click)="moveUp.emit()" style="width: 50px;" *ngSwitchCase="true" matTooltip="Move up in order" mat-icon-button>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
      <button (click)="moveDown.emit()" style="width: 50px;" *ngSwitchCase="true" matTooltip="Move down in order" mat-icon-button>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button (click)="delete.emit()" style="width: 50px;" *ngSwitchCase="true" mat-icon-button color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
