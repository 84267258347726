import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDisputeWithOrderAndListing } from 'wz-types/disputes';
import { Globals } from '~shared/classes';
import { DisputesStore } from '~shared/stores';

@Component({
  selector: 'wz-disputes-page',
  templateUrl: './disputes-page.component.html',
  styleUrls: ['./disputes-page.component.scss']
})
export class DisputesPageComponent implements OnInit {
  disputes$: Observable<IDisputeWithOrderAndListing[]>;
  archiveDisputes$: Observable<IDisputeWithOrderAndListing[]>;
  isLoading = true;
  isLoadingArchivedDisputes: boolean;

  constructor(
    private disputesStore: DisputesStore
  ) { }

  ngOnInit() {
    Globals.userInstantiated$.pipe(
      map(() => {
        this.disputes$ = this.disputesStore.dispatch('getDisputes').pipe(
          map((disputes: any[]) => {
            this.isLoading = false;
            return disputes.filter(d => !!d.listing);
          })
        );
      })
    ).subscribe();
  }

  filterResolved(disputes: IDisputeWithOrderAndListing[], getResolved: boolean): IDisputeWithOrderAndListing[] {
    return disputes.filter(d => !getResolved ? !d.isResolved : d.isResolved);
  }

  selectedArchiveTab() {
    this.isLoadingArchivedDisputes = true;
    this.archiveDisputes$ = this.disputesStore.dispatch('getDisputes', true).pipe(
      map(r => {
        this.isLoadingArchivedDisputes = false;
        return r;
      })
    );
  }

}
