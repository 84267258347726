import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Globals } from '~shared/classes';

import { AppModule } from './app/app.module';

// Pointless comment remove later ;)


if (Globals.environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
