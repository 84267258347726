import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ICallToAction } from 'wz-types/home-page';
import { SelectCategoryComponent } from '~shared/dialogs';

import { Category } from '../../classes/category.class';

@Component({
  selector: 'wz-cta-form',
  templateUrl: './cta-form.component.html',
  styleUrls: ['./cta-form.component.scss']
})
export class CtaFormComponent implements OnInit, OnChanges {
  @Input() name = 'Call to action';
  @Input() originalDoc: ICallToAction;
  @Output() updated: EventEmitter<ICallToAction> = new EventEmitter();
  ctaForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.updateForm();
  }

  ngOnChanges() {
    this.updateForm();
  }

  updateForm() {
    const getIf = (key: string) => this.originalDoc ? this.originalDoc[key] : undefined;
    this.ctaForm = this.formBuilder.group({
        title: [getIf('title'), Validators.required],
        message: [getIf('message')
      ],
      // boxColor: [!!getIf('boxColor') ? getIf('boxColor') : '#ffffff', Validators.required],
      // fontColor: [!!getIf('fontColor') ? getIf('fontColor') : '#000000', Validators.required],
      imgUrl: [getIf('imgUrl'), Validators.required],
      routerLink: [getIf('routerLink'), [Validators.required]],
      routerLinkName: [getIf('routerLinkName'), [Validators.required]]
    });
  }

  getCtrl(ctrlName: string) {
    return this.ctaForm.get(ctrlName);
  }

  submitCtaForm() {
    const newCta: ICallToAction = this.ctaForm.getRawValue();
    this.updated.emit(newCta);
    this.ctaForm.markAsUntouched();
  }

  updateImgUrl(imgUrl: string) {
    this.ctaForm.markAsTouched();
    this.getCtrl('imgUrl').setValue(imgUrl);
  }

  selectCategoryLink() {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      hasBackdrop: true,
      width: '90%',
      maxWidth: '350px',
      data: { exlude: [], previouslySelectedIds: [], single: true }
    });

    dialogRef.afterClosed().pipe(
      map((cats: Category[]) => {
        const category = cats[0];
        this.getCtrl('routerLink').setValue(['category', category.id]);
        this.getCtrl('routerLinkName').setValue(category.name);
        this.ctaForm.markAsTouched();
      })
    ).subscribe();
  }

}
