import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IWeddingColor } from 'wz-types/home-page';
import { AlertService } from '~shared/services';

import { WeddingColorsService } from '../../services/wedding-colors/wedding-colors.service';

@Component({
  selector: 'wz-create-edit-wedding-color',
  templateUrl: './create-edit-wedding-color.component.html',
  styleUrls: ['./create-edit-wedding-color.component.scss']
})
export class CreateEditWeddingColorComponent implements OnInit {
  isEditing: boolean;
  colorForm: FormGroup;
  colorDoc: IWeddingColor;
  selectedColor = '#ffffff';
  originalImgUrl: string;

  constructor(
    public dialogRef: MatDialogRef<CreateEditWeddingColorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private weddingColorsSrv: WeddingColorsService,
    private alertSrv: AlertService
  ) { }

  ngOnInit() {
    this.isEditing = !!this.data && !!this.data.colorDoc;
    if (this.isEditing) {
      this.colorDoc = this.data.colorDoc;
      this.originalImgUrl = this.colorDoc.imgUrl;
      this.selectedColor = this.colorDoc.hexColor;
    }
    const getProp = (name: string) => !!this.colorDoc ? this.colorDoc[name] : undefined;
    this.colorForm = this.formBuilder.group({
      id: [this.isEditing ? this.colorDoc.id : this.firestore.createId(), Validators.required],
      name: [getProp('name'), Validators.required],
      hexColor: [getProp('hexColor')],
      imgUrl: [getProp('imgUrl'), Validators.required]
    });
  }

  submit() {
    this.colorForm.get('hexColor').setValue(this.selectedColor);
    const color: IWeddingColor = this.colorForm.getRawValue();

    let createOrUpdate = () => this.weddingColorsSrv.createWeddingColor(color);
    if (this.isEditing) createOrUpdate = () => this.weddingColorsSrv.updateWeddingColor(color);

    this.alertSrv.confirm(`${this.isEditing ? 'Change ' : 'Create '} color?`, 'All changes will go live on the site.').pipe(
      filter((didConfirm) => didConfirm),
      mergeMap(() => createOrUpdate()),
      map(() => {
        this.alertSrv.successToast(`${color.name} ${this.isEditing ? 'changed' : 'created'}!`);
        this.dialogRef.close(true);
      })
    ).subscribe();
  }

  imgAdded(imgUrl) {
    this.colorForm.markAsTouched();
    this.colorForm.get('imgUrl').setValue(imgUrl);
  }

}
