import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { IListing } from 'wz-types/listings';
import { FirestoreRefs } from '~shared/classes';

@Component({
  selector: 'wz-items-page',
  templateUrl: './items-page.component.html',
  styleUrls: ['./items-page.component.scss']
})
export class ItemsPageComponent implements OnInit {
  listings: IListing[] = [];
  firstTimestamp: number;
  lastTimestamp: number;
  searchText$: BehaviorSubject<string> = new BehaviorSubject(undefined as any);

  constructor() { }

  ngOnInit() {
    this.searchText$.pipe(
      debounceTime(350),
      map(async (text: string) => {
        if (text) {
          const querySnap = await FirestoreRefs.listings.ref.orderBy('id').startAt(text).endAt(`${text}\uf8ff`).limit(40).get();
          this.listings = (querySnap.docs || []).map(d => d.data()) as any;  
        } else {
          this.paginateListings();
        }  
      })
    ).subscribe();
  }

  async paginateListings(clickEvent?: 'right' | 'left') {
    let query = FirestoreRefs.listings.ref.orderBy('createdTimestamp', 'desc').limit(40);
    if (clickEvent === 'left') {
      query = FirestoreRefs.listings.ref.orderBy('createdTimestamp', 'asc').startAfter(this.firstTimestamp).limit(40);
    } else if (clickEvent === 'right') {
      query = query.startAfter(this.lastTimestamp);
    }
    const querySnap = await query.get();
    this.listings = querySnap.docs.map(d => d.data()) as any;
    if (clickEvent === 'left') {
      this.listings.reverse();
    }
    this.firstTimestamp = this.listings[0] ? this.listings[0].createdTimestamp : undefined;
    this.lastTimestamp = this.listings[this.listings.length - 1] ? this.listings[this.listings.length - 1].createdTimestamp : undefined;
  }



}
